import React, { useEffect, useState } from "react";
import Linechart from "../../../components/Linechart/Linechart";
import Linecharttwo from "../../../components/Linechart/Linecharttwo";
import { Axios } from "../../../lib";
import { Link } from "react-router-dom";
import Linechartthree from "../../../components/Linechart/Linechartthree";
import { APP_ROUTES } from "../../../routes";
import dashstyle from "./style.module.css";
// import wemcoleimg from '../../../assets/images/welcomeimg.png';
import waveimg from '../../../assets/images/wave-img.png';
import homeimg01 from '../../../assets/images/homeimg01.jpg';


const VendorDashboard = ({ loader }) => {
    const [vendorDash, setvendorDash] = useState({});
    const vendorDashboard = async () => {
        try {
            const { status, data } = await Axios.get("v1/vendor/dashboard", {}, loader);
            if (status) setvendorDash(data);
        } catch (error) {
            console.error("ERROR-->", error);
        }
    };

    useEffect(() => {
        vendorDashboard();
    }, []);

    console.log(vendorDash, ";;;;;");

    return (
        <>
            <div className={`${dashstyle["proerty-tabs"]}`} >
                {/* <div className="row">
                    <div className="12">
                        <div className="WelCome">
                            <p>Welcome Back to Rent VIP Portal.</p>
                            <img src={waveimg} alt="Wave" />
                        </div>
                    </div>
                </div> */}

                <div className={`row RowGap justify-content-center ${dashstyle["dashboard-row"]}`}>

                    <div className="col-lg-4 col-md-6 mb-4">
                        <Link to={APP_ROUTES.VENDOR_REVENUE_LIST} className={dashstyle["card-link"]}>
                            <div className={`${dashstyle["inner-tabs"]} ${dashstyle["card-shadow"]} ${dashstyle["card-shadow01"]} ${dashstyle["dashboard-card"]}`}>
                                <span className={dashstyle["card-icon"]}>
                                    <i className="fas fa-coins"></i>
                                </span>
                                <h5 className={dashstyle["card-title"]}>Total Revenue</h5>
                                <h4 className={dashstyle["total-amount"]}>{vendorDash?.total_revenue ? `$${parseFloat(vendorDash.total_revenue).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` : '$0.00'}</h4>
                                <div className={dashstyle["revenue-details"]}>
                                    <div className={dashstyle["revenue-detail-item"]}>
                                        <div className={dashstyle["revenue-label"]}>Property</div>
                                        <div className={dashstyle["revenue-value"]}>${vendorDash?.totalPropertyRevenue ? parseFloat(vendorDash.totalPropertyRevenue).toFixed(2) : '0.00'}</div>
                                    </div>
                                    <div className={dashstyle["revenue-detail-item"]}>
                                        <div className={dashstyle["revenue-label"]}>Vehicle</div>
                                        <div className={dashstyle["revenue-value"]}>${vendorDash?.totalCarRevenue ? parseFloat(vendorDash.totalCarRevenue).toFixed(2) : '0.00'}</div>
                                    </div>
                                    <div className={dashstyle["revenue-detail-item"]}>
                                        <div className={dashstyle["revenue-label"]}>Tours</div>
                                        <div className={dashstyle["revenue-value"]}>${vendorDash?.totalTourRevenue ? parseFloat(vendorDash.totalTourRevenue).toFixed(2) : '0.00'}</div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 mb-4">
                        <Link to={APP_ROUTES.VENDOR_DAILY_REVENUE} className={dashstyle["card-link"]}>
                            <div className={`${dashstyle["inner-tabs"]} ${dashstyle["card-shadow"]} ${dashstyle["card-shadow02"]} ${dashstyle["dashboard-card"]}`}>
                                <span className={dashstyle["card-icon"]}>
                                    <i className="fas fa-chart-pie"></i>
                                </span>
                                <h5 className={dashstyle["card-title"]}>Daily Revenue</h5>
                                <h4 className={dashstyle["total-amount"]}>{vendorDash?.daily_revenue ? `$${parseFloat(vendorDash.daily_revenue).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` : '$0.00'}</h4>
                                <div className={dashstyle["revenue-details"]}>
                                    <div className={dashstyle["revenue-detail-item"]}>
                                        <div className={dashstyle["revenue-label"]}>Property</div>
                                        <div className={dashstyle["revenue-value"]}>${vendorDash?.dailyPropertyRevenue ? parseFloat(vendorDash.dailyPropertyRevenue).toFixed(2) : '0.00'}</div>
                                    </div>
                                    <div className={dashstyle["revenue-detail-item"]}>
                                        <div className={dashstyle["revenue-label"]}>Vehicle</div>
                                        <div className={dashstyle["revenue-value"]}>${vendorDash?.dailyCarRevenue ? parseFloat(vendorDash.dailyCarRevenue).toFixed(2) : '0.00'}</div>
                                    </div>
                                    <div className={dashstyle["revenue-detail-item"]}>
                                        <div className={dashstyle["revenue-label"]}>Tours</div>
                                        <div className={dashstyle["revenue-value"]}>${vendorDash?.dailyTourRevenue ? parseFloat(vendorDash.dailyTourRevenue).toFixed(2) : '0.00'}</div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className="col-lg-4 col-md-6 mb-4">
                        <Link to={APP_ROUTES.PROPERTY_CALENDAR} className={dashstyle["card-link"]}>
                            <div className={`${dashstyle["inner-tabs"]} ${dashstyle["card-shadow"]} ${dashstyle["card-shadow03"]} ${dashstyle["dashboard-card"]}`}>
                                <span className={dashstyle["card-icon"]}>
                                    <i className="fas fa-city"></i>
                                </span>
                                <h5 className={dashstyle["card-title"]}>Total Booking Requests</h5>
                                <h4 className={dashstyle["total-amount"]}>{vendorDash?.total_booking_requests?.property || 0}</h4>
                                <div className={dashstyle["revenue-details"]}>
                                    <div className={dashstyle["revenue-detail-item"]}>
                                        <div className={dashstyle["revenue-label"]}>Property</div>
                                        <div className={dashstyle["revenue-value"]}>{vendorDash?.total_booking_requests?.property || 0}</div>
                                    </div>
                                    <div className={dashstyle["revenue-detail-item"]}>
                                        <div className={dashstyle["revenue-label"]}>Vehicle</div>
                                        <div className={dashstyle["revenue-value"]}>{vendorDash?.total_booking_requests?.tours || 0}</div>
                                    </div>
                                    <div className={dashstyle["revenue-detail-item"]}>
                                        <div className={dashstyle["revenue-label"]}>Tours</div>
                                        <div className={dashstyle["revenue-value"]}>{vendorDash?.total_booking_requests?.vehicle || 0}</div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>

                </div>
                {/* Row End */}

                <div className="row mt-2">
                    <div className="col-lg-4 col-md-6">
                        <div className="pie-chart-left card-shadow">
                            <h3>Properties</h3>
                            <Linechart data={vendorDash?.bookedProperties} type="PROPERTY" />
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="pie-chart-right card-shadow">
                            <h3>Cars</h3>
                            <Linecharttwo data={vendorDash?.bookedCars} type="CAR" />
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="pie-chart-right card-shadow">
                            <h3>Tours</h3>
                            <Linechartthree data={vendorDash?.bookedCars} type="TOUR" />
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};

export default VendorDashboard;
