import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { Button } from "react-bootstrap";
import ImageModal from "./EditPopup/ImageModal";
import { USER_TYPE, EDIT_PROPERTY_TYPE } from "../../../../constants";
import styles from "./style.module.css";

const PropertyImageGallery = ({
  windowWidth,
  imageCategory,
  property,
  openGalleryModal,
  user,
  setEditProperty,
  id
}) => {
  return (
    <div className="container-fluid">
      {windowWidth <= 767 ? (
        // Mobile carousel view with full-width images
        <div className={styles.mobilePropertySlider}>
          <Slider
            dots={true}
            infinite={true}
            speed={500}
            slidesToShow={1}
            slidesToScroll={1}
            arrows={false}
            autoplay={true}
            autoplaySpeed={3000}
            className="mobile-main-slider"
          >
            {imageCategory.length > 0 ?
              imageCategory.map((img, index) => (
                <div key={index} className={styles.mobileSlideItem}>
                  <img
                    src={img}
                    alt={`Property Image ${index + 1}`}
                    className="img-fluid w-100"
                    onClick={() => openGalleryModal(index)}
                  />
                </div>
              )) :
              property?.images?.map((img, index) => (
                <div key={index} className={styles.mobileSlideItem}>
                  <img
                    src={img}
                    alt={`Property Image ${index + 1}`}
                    className="img-fluid w-100"
                    onClick={() => openGalleryModal(index)}
                  />
                </div>
              ))
            }
          </Slider>
        </div>
      ) : (
        // Desktop view with grid layout
        <div className="row g-1">
          <div className="col-md-6 mt-4">
            <img
              src={imageCategory.length > 0 ? imageCategory[0] : property?.images?.[0] || "placeholder.jpg"}
              className={`img-fluid w-100 ${styles.propertyMainImage}`}
              alt="Hotel Images"
              onClick={() => openGalleryModal(0)}
            />
          </div>
          <div className="col-md-3 mt-4 d-flex flex-column gap-1">
            <img
              src={imageCategory.length > 1 ? imageCategory[1] : (imageCategory[0] || property?.images?.[1] || "placeholder.jpg")}
              className={`img-fluid w-100 ${styles.propertyGridImage}`}
              alt="Hotel Images"
              onClick={() => openGalleryModal(0)}
            />
            <img
              src={imageCategory.length > 2 ? imageCategory[2] : (imageCategory[0] || property?.images?.[2] || "placeholder.jpg")}
              className={`img-fluid w-100 ${styles.propertyGridImage}`}
              alt="Hotel Images"
              onClick={() => openGalleryModal(0)}
            />
          </div>

          <div className="col-md-3 mt-4 d-flex flex-column gap-1">
            <img
              src={imageCategory.length > 3 ? imageCategory[3] : (imageCategory[0] || property?.images?.[3] || "placeholder.jpg")}
              className={`img-fluid w-100 ${styles.propertyGridImageTop}`}
              alt="Hotel Images"
              onClick={() => openGalleryModal(0)}
            />
            <img
              src={imageCategory.length > 4 ? imageCategory[4] : (imageCategory[0] || property?.images?.[4] || "placeholder.jpg")}
              className={`img-fluid w-100 ${styles.propertyGridImageMiddle}`}
              alt="Hotel Images"
              onClick={() => openGalleryModal(0)}
            />
            <div className="position-relative">
              <img
                src={imageCategory.length > 5 ? imageCategory[5] : (imageCategory[0] || property?.images?.[5] || "placeholder.jpg")}
                className={`img-fluid w-100 ${styles.propertyGridImageBottom}`}
                alt="Hotel Images"
                onClick={() => openGalleryModal(0)}
              />
              <div className="position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center">
                {imageCategory.length > 0 ? (
                  user.userType === USER_TYPE.VENDOR ? (
                    <Button
                      className="InactiveBtn"
                      onClick={() =>
                        setEditProperty({
                          show: true,
                          type: EDIT_PROPERTY_TYPE.IMAGES,
                        })
                      }
                    >
                      Edit
                    </Button>
                  ) : null
                ) : (
                  user.userType === USER_TYPE.VENDOR ? (
                    <ImageModal
                      property_id={id}
                      image={property?.images}
                      imagesId={property?.imagesId}
                    />
                  ) : null
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PropertyImageGallery; 