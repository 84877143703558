import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import Swal from "sweetalert2";
import Slider from "react-slick";

import { Pagination, Button, PropertyCarTour } from "../../../components";
import {
  SORTING_FIELDS,
  USER_TYPE,
  CATEGORY_TYPE,
  MESSAGES,
} from "../../../constants";
import { APP_ROUTES } from "../../../routes";
import { CommonAction } from "../../../store/common/thunk";
import { AdminTourAction } from "../../../store/admin/thunk";
import { VendorTourAction } from "../../../store/vendor/thunk";
import nsearch from "../../../assets/images/nsearch.png";
import fltic from "../../../assets/images/fltic.png";
import dn from "../../../assets/images/dn.png";
import {
  DeleteIcon,
  FilterIcon,
  LocationWhiteIcon,
  SearchIcon,
  ViewIcon,
} from "../../../assets/images";
import tableStyles from "../../../components/Table/style.module.css";
import commonStyles from "../styles/common.module.css";
import AddButton from "../../../components/Button/AddButton";

const Tours = ({
  loader,
  getToursList,
  approveTour,
  removeTour,
  categoriesList,
}) => {
  const navigate = useNavigate();

  const { user, toursList, toursCount, categories, loadingTourList } =
    useSelector((state) => state.common);

  const [textSearch, setTextSearch] = useState("");
  const [pagination, setPagination] = useState({ offset: 0, limit: 6 });
  const [category, setCategory] = useState(null);
  const [approval, setApproval] = useState(null);
  const [sorting, setSorting] = useState({ type: null, order: null });
  const [mobFilter, setMobFilter] = useState(false);

  const getTours = async (
    offset = pagination.offset,
    search = textSearch,
    categoryId = category,
    approvalId = approval
  ) => {
    let query = {
      limit: pagination.limit,
      offset,
      sort: sorting.type,
      sortType: sorting.order,
    };

    if (search && search.length) query.searchString = search;
    if (categoryId) query.categoryId = categoryId;
    if (approvalId) query.isApproved = parseInt(approvalId, 10) === 1;

    getToursList(query, loader);
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value.length ? e.target.value : null);
    setPagination({ offset: 0, limit: pagination.limit });
  };

  const handleApprovalChange = (e) => {
    setApproval(e.target.value.length ? e.target.value : null);
    setPagination({ offset: 0, limit: pagination.limit });
  };

  const clearResult = () => {
    setCategory("");
    setApproval("");
    setTextSearch("");

    getTours(0, null, null, "");
  };

  const switchHandler = async (tourId, isApproved) => {
    const query = { tourId: tourId, isApproved };

    approveTour(query, loader, pagination);
  };

  const deleteTour = async (tourId) => {
    Swal.fire({
      title: MESSAGES.REMOVE_REQUEST,
      text: MESSAGES.TOUR.REQUEST_REMOVE,
      confirmButtonText: "Yes",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const query = { tourId };

        removeTour(query, loader, pagination);
      }
    });
  };

  const handleSortingChange = (e) => {
    const value = e.target.value.split("_");

    if (value.length > 1) {
      setSorting({ type: value[0], order: parseInt(value[1]) === 0 ? -1 : 1 });
    } else setSorting({ type: null, value: null });
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  useEffect(() => {
    getTours();
  }, [pagination, sorting]);

  useEffect(() => {
    const query = { type: CATEGORY_TYPE.TOUR };

    categoriesList(query, loader);
  }, []);

  return (
    <>
      <PropertyCarTour />
      <div className={commonStyles["vendor-listing"]}>
        <div className="d-flex justify-content-between mb-3 align-items-center no-margin">
          <div className="d-flex justify-content-between align-items-center">
            <ul className="paginaion">
              <li>
                <Link to="/">Dashboard</Link>
              </li>
              <li className="active">
                <Link to="/tours">Tours</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className={commonStyles["filter-main"]}>
          <div className={commonStyles["filter-left"]}>
            <div className="form-group position-relative mobFilter">
              <span className={commonStyles["filer-iic"]}>
                <img src={FilterIcon} alt="filter" />
              </span>
              <select
                name="categories"
                onChange={handleApprovalChange}
                className="mx-1"
                value={approval}
              >
                <option value="">Filter by Approval</option>
                <option key="1" value="1">
                  Approved
                </option>
                <option key="0" value="0">
                  Pending
                </option>
              </select>
            </div>
            <div className="form-group position-relative mobFilter">
              <span className={commonStyles["filer-iic"]}>
                <img src={FilterIcon} alt="filter" />
              </span>
              <select
                name="categories"
                onChange={handleCategoryChange}
                className="mx-1"
                value={category}
              >
                <option value="">Filter by Category</option>
                {categories.map((category) => (
                  <option key={category._id} value={category._id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group position-relative mobFilter">
              <span className={commonStyles["filer-iic"]}>
                <img src={FilterIcon} alt="filter" />
              </span>
              <select
                name="sorting"
                className="me-1"
                onChange={handleSortingChange}
                value={`${sorting.type}_${sorting.order}`}
              >
                <option value="">Sort</option>
                <option value={SORTING_FIELDS.NAME_ASC}>Name (ASC)</option>
                <option value={SORTING_FIELDS.NAME_DEC}>Name (DEC)</option>
                <option value={SORTING_FIELDS.TOUR_CATEGORY_ASC}>
                  Category (ASC)
                </option>
                <option value={SORTING_FIELDS.TOUR_CATEGORY_DEC}>
                  Category (DEC)
                </option>
                <option value={SORTING_FIELDS.LOCATION_ASC}>
                  Location (ASC)
                </option>
                <option value={SORTING_FIELDS.LOCATION_DEC}>
                  Location (DEC)
                </option>
              </select>
            </div>
            <div className="form-group position-relative">
              <span className={tableStyles["search-c"]}>
                <img className="desk-ic" src={SearchIcon} alt="search" />
                <img className="mob-ic" src={nsearch} alt="search" />
              </span>
              <input
                type="text"
                placeholder="Search by name"
                onChange={(e) => setTextSearch(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") getTours(0);
                }}
                value={textSearch}
              />
              {textSearch.length || category || approval ? (
                <button
                  className={tableStyles["search-button"]}
                  onClick={clearResult}
                >
                  <i className="fa fa-eraser" />
                </button>
              ) : null}
            </div>
            <div className="propertyType">
              <select>
                <option>Propery Type</option>
              </select>
              <span>
                <img src={dn} alt="search" />
              </span>
            </div>
            <div className="FilterMob">
              <span onClick={() => setMobFilter(!mobFilter)}>
                <img src={fltic} alt="" />
              </span>
              {mobFilter && (
                <div className="filterMobDropdown">
                  <div className="form-group position-relative">
                    <span className={commonStyles["filer-iic"]}>
                      <img src={FilterIcon} alt="filter" />
                    </span>
                    <select
                      name="categories"
                      onChange={handleApprovalChange}
                      className="mx-1"
                      value={approval}
                    >
                      <option value="">Filter by Approval</option>
                      <option key="1" value="1">
                        Approved
                      </option>
                      <option key="0" value="0">
                        Pending
                      </option>
                    </select>
                  </div>
                  <div className="form-group position-relative">
                    <span className={commonStyles["filer-iic"]}>
                      <img src={FilterIcon} alt="filter" />
                    </span>
                    <select
                      name="categories"
                      onChange={handleCategoryChange}
                      className="mx-1"
                      value={category}
                    >
                      <option value="">Filter by Category</option>
                      {categories.map((category) => (
                        <option key={category._id} value={category._id}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group position-relative">
                    <span className={commonStyles["filer-iic"]}>
                      <img src={FilterIcon} alt="filter" />
                    </span>
                    <select
                      name="sorting"
                      className="me-1"
                      onChange={handleSortingChange}
                      value={`${sorting.type}_${sorting.order}`}
                    >
                      <option value="">Sort</option>
                      <option value={SORTING_FIELDS.NAME_ASC}>
                        Name (ASC)
                      </option>
                      <option value={SORTING_FIELDS.NAME_DEC}>
                        Name (DEC)
                      </option>
                      <option value={SORTING_FIELDS.TOUR_CATEGORY_ASC}>
                        Category (ASC)
                      </option>
                      <option value={SORTING_FIELDS.TOUR_CATEGORY_DEC}>
                        Category (DEC)
                      </option>
                      <option value={SORTING_FIELDS.LOCATION_ASC}>
                        Location (ASC)
                      </option>
                      <option value={SORTING_FIELDS.LOCATION_DEC}>
                        Location (DEC)
                      </option>
                    </select>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="webadd-btn">
            {user.userType === USER_TYPE.ADMIN ? null : (
              <Button
                type="button"
                handleClick={() => navigate(APP_ROUTES.ADD_TOUR)}
                title="Add"
              />
            )}
          </div>
        </div>
        <div className="new-tab-design">
          <div className="row">
            {loadingTourList ? (
              <div className="d-flex justify-content-center align-items-center flex-column loading-text">
                <h5>{MESSAGES.LOADING}</h5>
              </div>
            ) : null}
            {!loadingTourList && toursList.length === 0 ? (
              <p className="text-center">{MESSAGES.TOUR.NOT_FOUND}</p>
            ) : null}
            {!loadingTourList &&
              toursList.map((tour) => (
                <div className="col-md-4" key={tour._id}>
                  <div className={commonStyles["inner-tab-deisign"]}>
                    <div className={commonStyles["top-card-had"]}>
                      <div className={commonStyles["left-slider"]}>
                        <Slider {...settings}>
                          {tour.images.map((image, key) => (
                            <div key={key}>
                              <img src={image} alt="property" />
                            </div>
                          ))}
                        </Slider>
                       
                        <div className={commonStyles.overlay__}>
                          <div className={commonStyles["action-btn"]}>
                            {user.userType === USER_TYPE.VENDOR ? (
                              <button
                                className={commonStyles["delet-btn"]}
                                onClick={() => deleteTour(tour._id)}
                              >
                                <img src={DeleteIcon} alt="deleteTour" />
                              </button>
                            ) : null}
                            <Link to={`/tour/${tour._id}`}>
                              <button className={commonStyles["view-btn"]}>
                                {" "}
                                <img src={ViewIcon} alt="view-tour" />
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className={commonStyles["loc-left"]}>
                        <h3>{tour.name}</h3>
                        {user.userType === USER_TYPE.ADMIN && (
                          <label className={commonStyles.switch}>
                            <input
                              type="checkbox"
                              onChange={() =>
                                switchHandler(tour._id, !tour.isApproved)
                              }
                              checked={tour.isApproved}
                            />
                            <span
                              className={`round ${commonStyles["slider"]}`}
                            ></span>
                          </label>
                        )}
                      </div>
                      <div className={commonStyles["locaton-card"]}>
                          <p>
                            <img src={LocationWhiteIcon} alt="location" />
                            {tour.location}
                          </p>
                        </div>
                    </div>
                    <div className={commonStyles.refrela}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className={commonStyles["rf-left"]}>
                            <h6>Reference No:</h6>
                            <span>{tour.referenceNumber}</span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className={commonStyles["rf-left"]}>
                            <h6>Category:</h6>
                            <span>
                              {tour.categoryData?.name || tour.category}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {user.userType === USER_TYPE.VENDOR ? (
            <AddButton route={APP_ROUTES.ADD_TOUR} />
          ) : null}
          <Pagination
            count={toursCount}
            setPagination={setPagination}
            pageSize={pagination.limit}
          />
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getToursList: (query, loader) =>
    dispatch(CommonAction.toursList(query, loader)),
  approveTour: (params, loader, pagination) =>
    dispatch(AdminTourAction.approve(params, loader, pagination)),
  removeTour: (query, loader, pagination) =>
    dispatch(VendorTourAction.remove(query, loader, pagination)),
  categoriesList: (query, loader) =>
    dispatch(CommonAction.categoriesList(query, loader)),
});

export default connect(null, mapDispatchToProps)(Tours);
