import React from "react";
import { Navigate } from "react-router-dom";

import Page404 from "../components/Auth/Page404";
import ResetPass from "../pages/Auth/ResetPassword";
import CarBookingRequests from "../pages/Vendor/Requests/Car";
import AddCategory from "../pages/Admin/Category/Add";
import AddSubCategory from "../pages/Admin/Category/SubCategory/Add";
import Categories from "../pages/Admin/Category";
import Category from "../pages/Admin/Category/SubCategory";
import ListCMS from "../pages/Admin/CMS";
import EditCMS from "../pages/Admin/CMS/Add";
import AddFaq from "../pages/Admin/Faq/Add";
import Faqs from "../pages/Admin/Faq";
import Notifications from "../pages/Common/Notification";
import AddProperty from "../pages/Vendor/Property/Add";
import ListingProperty from "../pages/Common/Property";
import AddSubAdmin from "../pages/Admin/SubAdmin/Add";
import SubAdmins from "../pages/Admin/SubAdmin";
import Edituser from "../pages/Admin/User/Edit";
import User from "../pages/Admin/User";
import ViewUser from "../pages/Admin/User/View";
import Vendor from "../pages/Admin/Vendor";
import ViewVendor from "../pages/Admin/Vendor/View";
import NewChats from "../pages/Common/Chat";
import Tourdetail from "../pages/Common/Tour/View";
import Revenue from "../components/Dashboard/Revenue";
import Dailyrevenue from "../components/Dashboard/Dailyrevenue";
import Vendorrevenue from "../components/Dashboard/Vendorrevenue";
import Vendorreview from "../components/Dashboard/Vendorreview";
import Areas from "../pages/Admin/Areas";
import Buildings from "../pages/Admin/Buildings";
import AddArea from "../pages/Admin/Areas/Add";
import AddBuilding from "../pages/Admin/Buildings/Add";
import SubAdmin from "../pages/Admin/SubAdmin/View";
import PropertyCalendar from "../pages/Vendor/Calendar/Property";
import TourCalendar from "../pages/Vendor/Calendar/Tour";
import AdminPromotions from "../pages/Admin/Promotions";
import AddAdminPromotions from "../pages/Admin/Promotions/Add";
import PropertyPromotions from "../pages/Vendor/Property/Promotions";
import SelectVendorPromotions from "../pages/Vendor/Property/Promotions/Select";
import AddPropertyPromotion from "../pages/Vendor/Property/Promotions/Add";
import Cities from "../pages/Admin/Cities";
import AddCity from "../pages/Admin/Cities/Add";
import Vouchers from "../pages/Admin/Vouchers";
import AddVoucher from "../pages/Admin/Vouchers/Add";
import ClaimedUsers from "../pages/Admin/Vouchers/ClaimedUsers";
import Cars from "../pages/Common/Car";
import AddCar from "../pages/Vendor/Car/Add";
import ViewCar from "../pages/Common/Car/View";
import Tours from "../pages/Common/Tour";
import Addtours from "../pages/Vendor/Tour/Add";
import PropertyBookingRequests from "../pages/Vendor/Requests/Property";
import ToursBookingRequest from "../pages/Vendor/Requests/Tour";
import SpecificPropertyBooking from "../pages/Vendor/Requests/Specific";
import ViewProperty from "../pages/Common/Property/View";
import Payouts from "../pages/Vendor/Payouts";
import ViewInvoices from "../pages/Common/Invoices";
import SpecificVendorPayout from "../pages/Admin/SpecificVendorPayout";
import { CATEGORY_TYPE, USER_TYPE } from "../constants";
import { APP_ROUTES } from "../routes";
import ViewPayoutSettings from "../pages/Vendor/Payouts/Settings";
import AddCard from "../pages/Vendor/Payouts/Settings/AddCard";
import ViewPayoutCard from "../pages/Admin/Payouts";
import ImageCat from "../pages/Admin/ImageCat";
import AddImageCat from "../pages/Admin/ImageCat/Add";
import Teams from "../pages/Vendor/Team";
import { AddHotel } from "../pages/Common/Property/AddHotel";
import AddRoom from "../pages/Common/Property/AddRoom";

const privateRoutes = setLoader => [
  {
    path: APP_ROUTES.SUB_ADMIN_LIST,
    element: <SubAdmins loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN]
  },
  {
    path: APP_ROUTES.USERS_LIST,
    element: <User loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN]
  },
  {
    path: APP_ROUTES.EDIT_USER,
    element: <Edituser loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN]
  },
  {
    path: APP_ROUTES.VIEW_USER,
    element: <ViewUser loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN]
  },
  {
    path: APP_ROUTES.VENDOR_LIST,
    element: <Vendor loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN]
  },
  {
    path: APP_ROUTES.PROPERTIES_LIST,
    element: <ListingProperty loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN, USER_TYPE.VENDOR]
  },

  {
    path: APP_ROUTES.CARS_LIST,
    element: <Cars loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN, USER_TYPE.VENDOR]
  },
  {
    path: APP_ROUTES.TOURS_LIST,
    element: <Tours loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN, USER_TYPE.VENDOR]
  },
  {
    path: APP_ROUTES.CATEGORIES_LIST,
    element: <Categories loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN]
  },
  {
    path: APP_ROUTES.CITIES_LIST,
    element: <Cities loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN]
  },

  {
    path: APP_ROUTES.IMAGE_CATEGORY,
    element: <ImageCat loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN]
  },

  {
    path: APP_ROUTES.EDIT_IMAGE_CATEGORY,
    element: <AddImageCat loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN]
  },

  {
    path: APP_ROUTES.ADD_IMAGE_CATEGORY,
    element: <AddImageCat loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN]
  },

  {
    path: APP_ROUTES.CHATS_LIST,
    element: <NewChats />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN, USER_TYPE.VENDOR]
  },
  {
    path: APP_ROUTES.NOTIFICATIONS_LIST,
    element: <Notifications loader={setLoader} />,
    role: [USER_TYPE.ADMIN, USER_TYPE.VENDOR],
    privateComponent: true
  },
  {
    path: APP_ROUTES.CMS,
    element: <ListCMS loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN]
  },
  {
    path: APP_ROUTES.FAQS,
    element: <Faqs loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN, USER_TYPE.VENDOR]
  },
  {
    path: APP_ROUTES.VIEW_VENDOR,
    element: <ViewVendor loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN]
  },
  {
    path: APP_ROUTES.PROPERTY_DETAILS,
    element: <ViewProperty loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN, USER_TYPE.VENDOR]
  },
  {
    path: APP_ROUTES.CAR_DETAILS,
    element: <ViewCar loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN, USER_TYPE.VENDOR]
  },
  {
    path: APP_ROUTES.SUB_CATEGORY_LIST,
    element: <Category loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN]
  },
  {
    path: APP_ROUTES.ADD_CATEGORY,
    element: <AddCategory loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN]
  },
  {
    path: APP_ROUTES.EDIT_CATEGORY,
    element: <AddCategory loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN]
  },
  {
    path: APP_ROUTES.ADD_SUB_CATEGORY,
    element: <AddSubCategory loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN]
  },
  {
    path: APP_ROUTES.EDIT_SUB_CATEGORY,
    element: <AddSubCategory loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN]
  },

  {
    path: APP_ROUTES.ADD_CITY,
    element: <AddCity loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN]
  },
  {
    path: APP_ROUTES.ADD_CAR,
    element: <AddCar loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.VENDOR]
  },
  {
    path: "*",
    element: <Navigate replace to="/page-404" />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN, USER_TYPE.VENDOR]
  },
  {
    path: APP_ROUTES.ADD_TOUR,
    element: <Addtours loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.VENDOR]
  },
  {
    path: APP_ROUTES.TOUR_BOOKINGS,
    element: <ToursBookingRequest loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.VENDOR]
  },
  {
    path: APP_ROUTES.REVENUES_LIST,
    element: <Revenue loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN]
  },
  {
    path: APP_ROUTES.DAILY_REVENUE,
    element: <Dailyrevenue loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN]
  },
  {
    path: APP_ROUTES.VENDOR_REVENUE_LIST,
    element: <Vendorrevenue loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.VENDOR]
  },
  {
    path: APP_ROUTES.VENDOR_DAILY_REVENUE,
    element: <Vendorreview loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.VENDOR]
  },
  {
    path: "/reset-password/:id/:type/:token",
    element: <ResetPass loader={setLoader} />,
    privateComponent: true,
    admin: true,
    vendor: true
  },
  {
    path: APP_ROUTES.DASHBOARD,
    element: false,
    privateComponent: true,
    role: [USER_TYPE.ADMIN, USER_TYPE.VENDOR]
  },
  {
    path: APP_ROUTES.ADD_PROPERTY,
    element: <AddProperty loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.VENDOR]
  },
  {
    path: APP_ROUTES.VIEW_TOUR,
    element: <Tourdetail loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN, USER_TYPE.VENDOR]
  },
  {
    path: APP_ROUTES.EDIT_CITY,
    element: <AddCity loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN]
  },
  {
    path: APP_ROUTES.AREAS_LIST,
    element: <Areas loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN]
  },
  {
    path: APP_ROUTES.EDIT_AREA,
    element: <AddArea loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN]
  },
  {
    path: APP_ROUTES.BUILDINGS_LIST,
    element: <Buildings loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN]
  },
  {
    path: APP_ROUTES.EDIT_BUILDING,
    element: <AddBuilding loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN]
  },
  {
    path: APP_ROUTES.ADD_AREA,
    element: <AddArea loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN]
  },
  {
    path: APP_ROUTES.ADD_BUILDING,
    element: <AddBuilding loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN]
  },
  {
    path: APP_ROUTES.EDIT_CMS,
    element: <EditCMS loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN]
  },
  {
    path: APP_ROUTES.ADD_FAQ,
    element: <AddFaq loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN]
  },
  {
    path: APP_ROUTES.VIEW_PAYOUTS,
    element: <Payouts loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.VENDOR]
  },
  {
    path: APP_ROUTES.VIEW_INVOICES,
    element: <ViewInvoices loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN, USER_TYPE.VENDOR]
  },
  {
    path: APP_ROUTES.SPECIFIC_VENDOR_PAYOUTS,
    element: <SpecificVendorPayout loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN, USER_TYPE.VENDOR]
  },
  {
    path: APP_ROUTES.EDIT_FAQ,
    element: <AddFaq loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN]
  },
  {
    path: APP_ROUTES.PROPERTY_REQUESTS,
    element: <PropertyBookingRequests loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.VENDOR, USER_TYPE.ADMIN]
  },
  {
    path: APP_ROUTES.SPECIFIC_PROPERTY_REQUEST,
    element: (
      <SpecificPropertyBooking
        loader={setLoader}
        category={CATEGORY_TYPE.PROPERTY}
      />
    ),
    privateComponent: true,
    role: [USER_TYPE.VENDOR, USER_TYPE.ADMIN]
  },
  {
    path: APP_ROUTES.SPECIFIC_CAR_REQUEST,
    element: (
      <SpecificPropertyBooking
        loader={setLoader}
        category={CATEGORY_TYPE.CAR}
      />
    ),
    privateComponent: true,
    role: [USER_TYPE.VENDOR, USER_TYPE.ADMIN]
  },
  {
    path: APP_ROUTES.CAR_BOOKINGS_LIST,
    element: <CarBookingRequests loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.VENDOR, USER_TYPE.ADMIN]
  },
  {
    path: APP_ROUTES.ADD_SUB_ADMIN,
    element: <AddSubAdmin loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN]
  },
  {
    path: APP_ROUTES.VIEW_SUB_ADMIN,
    element: <SubAdmin loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN]
  },
  {
    path: APP_ROUTES.PROPERTY_CALENDAR,
    element: <PropertyCalendar loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.VENDOR]
  },
  {
    path: APP_ROUTES.TOUR_CALENDAR,
    element: <TourCalendar loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.VENDOR]
  },
  {
    path: APP_ROUTES.PROMOTIONS,
    element: <AdminPromotions loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN]
  },
  {
    path: APP_ROUTES.ADD_PROMOTION,
    element: <AddAdminPromotions loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN]
  },
  {
    path: APP_ROUTES.EDIT_PROMOTION,
    element: <AddAdminPromotions loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN]
  },
  {
    path: APP_ROUTES.PROPERTY_PROMOTION,
    element: <PropertyPromotions loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.VENDOR]
  },
  {
    path: APP_ROUTES.SELECT_NEW_PROMOTION,
    element: <SelectVendorPromotions loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.VENDOR]
  },
  {
    path: APP_ROUTES.ADD_PROPERTY_PROMOTION,
    element: <AddPropertyPromotion loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.VENDOR]
  },
  {
    path: APP_ROUTES.EDIT_PROPERTY_PROMOTION,
    element: <AddPropertyPromotion loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.VENDOR]
  },
  {
    path: APP_ROUTES.NEW_CHAT,
    element: <NewChats />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN, USER_TYPE.VENDOR]
  },
  {
    path: APP_ROUTES.VOUCHERS,
    element: <Vouchers loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN]
  },
  {
    path: APP_ROUTES.ADD_VOUCHER,
    element: <AddVoucher loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN]
  },
  {
    path: APP_ROUTES.CLAIMED_VOUCHER_USERS,
    element: <ClaimedUsers loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN]
  },
  {
    path: APP_ROUTES.EDIT_VOUCHER,
    element: <AddVoucher loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN]
  },
  {
    path: APP_ROUTES.VIEW_PAYOUTS_SETTINGS,
    element: <ViewPayoutSettings loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.VENDOR]
  },
  {
    path: APP_ROUTES.ADD_PAYOUT_CARD,
    element: <AddCard loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.VENDOR]
  },
  {
    path: APP_ROUTES.EDIT_PAYOUT_CARD,
    element: <AddCard loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.VENDOR]
  },
  {
    path: APP_ROUTES.VIEW_ADMIN_PAYOUTS,
    element: <ViewPayoutCard loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.ADMIN]
  },
  {
    path: APP_ROUTES.ADD_TEAM,
    element: <Teams loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.VENDOR]
  },
  {
    path: APP_ROUTES.ADD_HOTEL,
    element: <AddHotel />,
    privateComponent: true,
    role: [USER_TYPE.VENDOR]
  },
  {
    path: APP_ROUTES.ADD_ROOM,
    element: <AddRoom loader={setLoader} />,
    privateComponent: true,
    role: [USER_TYPE.VENDOR]
  },
  {
    path: "/page-404",
    element: <Page404 />,
    privateComponent: true,
    admin: true,
    vendor: true
  }
];

export default privateRoutes;
