import React, { useEffect, useState } from "react";
import Pagination from "../Pagination";
import { Axios } from "../../lib";
import commonStyles from "../../pages/Common/styles/common.module.css";
import tableStyles from "../../components/Table/style.module.css";

function Vendorrevenue({ loader }) {
    const [cities, setCities] = useState([]);
    const [revenuedata, setRevenue] = useState("PROPERTY");
    const [pagination, setPagination] = useState({
        offset: 0,
        limit: 10,
    });

    const getCities = async () => {
        try {
            const { status, data } = await Axios.get(
                `v1/revenue/vendor?type=${revenuedata}`,
                {
                    limit: pagination.limit,
                    offset: pagination.offset,
                },
                loader
            );
        
  
            if (status)
                setCities({
                    count: data.count,
                    cities: data,
                });
        } catch (error) {}
    };

  
    useEffect(() => {
        getCities();
      
    }, [pagination, revenuedata]);
  
    return (
        <div className={commonStyles["vendor-listing"]}>
            <ul>
                <li>
                    <span className={revenuedata === "PROPERTY" ? "active" : "inactive"} onClick={() => setRevenue("PROPERTY")}>Properties </span>
                </li>
                <li>
                    <span className={revenuedata === "CAR" ? "active" : "inactive"} onClick={() => setRevenue("CAR")}>Car </span>
                </li>
                <li>
                    <span className={revenuedata === "TOUR" ? "active" : "inactive"} onClick={() => setRevenue("TOUR")}>Tours </span>
                </li>
            </ul>
            {revenuedata === "PROPERTY" && 
            <>
                <h3 className="mt-5 mb-0">Property  Revenue</h3>
                <div className={tableStyles["listing-table"]}>
                    <div className={tableStyles["listing-table-scrl"]}>
                        <table cellPadding={0} cellSpacing={0}>
                            <tr>
                                <th>S No.</th>
                                <th>Property Name</th>
                                <th>User Name</th>
                                <th>Date</th>
                                <th>Total Revenue</th>
                
                            </tr>
   
                            {cities.cities
  &&  cities.cities.map((city, index) => (
      <tr key={city._id}>
          <td>{++index}</td>
                    
          <td>{city.name}</td>
          <td>{city.userFirstName} {city.userLastName}</td>
          <td>{city.createdAt.split("T")[0]}</td>
          <td>{city.totalAmount ? city.totalAmount : "-"}</td>
                   
                    
      </tr>
  ))}
                        </table>
                    </div>
   
                    <Pagination count={cities.count + 1} setPagination={setPagination} />
                </div>
         
            </>
            }

            {revenuedata === "CAR" && 
            <>
                <h3 className="mt-5">Car  Revenue</h3>
                <div className={tableStyles["listing-table"]}>
                    <div className={tableStyles["listing-table-scrl"]}>
                        <table cellPadding={0} cellSpacing={0}>
                            <tr>
                                <th>S No.</th>
                                <th>Car Name</th>
                                <th>User Name</th>
                                <th>Date</th>
                                <th>Total Revenue</th>
                
                            </tr>
   
                            {cities.cities
  &&  cities.cities.map((city, index) => (
      <tr key={city._id}>
          <td>{++index}</td>
          <td>
              {city.name} 
          </td>
          <td>{city.userFirstName} {city.userLastName}</td>
          <td>{city.createdAt.split("T")[0]}</td>
          <td>{city.totalAmount ? city.totalAmount : "-"}</td>
                   
                    
      </tr>
  ))}
                        </table>
                    </div>
   
                    <Pagination count={cities.count + 1} setPagination={setPagination} />
                </div>
         
            </>
            }
       

            {revenuedata === "TOUR" && 
            <>
                <h3 className="mt-5">Tours  Revenue</h3>
                <div className={tableStyles["listing-table"]}>
                    <div className={tableStyles["listing-table-scrl"]}>
                        <table cellPadding={0} cellSpacing={0}>
                            <tr>
                                <th>S No.</th>
                                <th>Car Name</th>
                                <th>User Name</th>
                                <th>Date</th>
                                <th>Total Revenue</th>
                
                            </tr>
   
                            {cities.cities
  &&  cities.cities.map((city, index) => (
      <tr key={city._id}>
          <td>{++index}</td>
          <td>
              {city.name} 
          </td>
          <td>{city.userFirstName} {city.userLastName}</td>
          <td>{city.createdAt.split("T")[0]}</td>
          <td>{city.totalAmount ? city.totalAmount : "-"}</td>
  
   
      </tr>
  ))}
                        </table>
                    </div>
   
                    <Pagination count={cities.count + 1} setPagination={setPagination} />
                </div>
         
            </>
            }
        </div>
    );
}

export default Vendorrevenue

