import React, { useState, useEffect, Fragment } from "react";
import { connect, useSelector } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import { debounce } from "lodash";

import { AdminInvoiceAction } from "../../../store/admin/thunk";
import { INVOICE_STATUS, MESSAGES, USER_TYPE } from "../../../constants";
import { Pagination } from "../../../components";
import { SearchIcon } from "../../../assets/images";
import { APP_ROUTES } from "../../../routes";
import styles from "./style.module.css";
import tableStyles from "../../../components/Table/style.module.css";
import commonStyles from "../styles/common.module.css";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { TransferReferenceSchema } from "../../../schemas";
import { VendorPayoutAction } from "../../../store/vendor/thunk";
import Swal from "sweetalert2";

const ViewInvoices = ({
  viewInvoices,
  loader,
  updatePayouts,
  specificPayoutCard,
}) => {
  const { invoices, invoicesCount, invoiceListLoader, user } = useSelector(
    (state) => state.common
  );

  const {
    payouts: { specificPayout },
  } = useSelector((state) => state.vendor);

  const [pagination, setPagination] = useState({ offset: 0, limit: 10 });
  const [invoiceNoSearch, setInvoiceNoSearch] = useState("");
  const [tranRefPopup, setTranRefPopup] = useState({ status: false, data: {} });
  const [bankPopup, setBankPopup] = useState({ status: false, data: {} });

  const getPayouts = (offset = pagination.offset) => {
    const query = {
      offset,
      limit: pagination.limit,
    };

    if (invoiceNoSearch && invoiceNoSearch?.length)
      query.invoiceNumber = invoiceNoSearch;
    viewInvoices(query, user.userType, loader);
  };

  const markPayouts = ({ invoiceId, transactionReference }) => {
    const query = {
      offset: pagination.offset,
      limit: pagination.limit,
    };

    updatePayouts(
      { invoiceId: invoiceId, transactionReference },
      loader,
      query,
      user.userType
    );
  };

  const bankDetails = async (bankAccountId) => {
    await specificPayoutCard(bankAccountId);
    setBankPopup({ status: true, data: specificPayout });
  };

  useEffect(() => {
    getPayouts();
  }, [pagination.offset]);

  const clearResult = () => {
    setInvoiceNoSearch("");
    getPayouts(0);
  };

  const debouncePayoutsList = debounce(() => getPayouts(0), 500);

  const TransferReferencePopup = () => {
    return (
      <div
        className={`modal ${tranRefPopup?.status ? "show" : "hide"}`}
        id="calendar-price"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add an Transaction reference.
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setTranRefPopup({ status: false, data: {} })}
              ></button>
            </div>
            <div className="modal-body">
              <Formik
                initialValues={{ transferReference: "" }}
                validationSchema={TransferReferenceSchema}
                onSubmit={async (values, { setSubmitting }) => {
                  setSubmitting(false);

                  Swal.fire({
                    // title: "Are you sure?",
                    text: `Are you sure you want to paid?`,
                    confirmButtonText: "Yes",
                    showCancelButton: true,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      markPayouts({
                        invoiceId: tranRefPopup?.data?.invoiceId,
                        transactionReference: values?.transferReference,
                      });
                      setTranRefPopup({ status: false, data: {} });
                    }
                  });
                }}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div className="mb-3">
                      <label htmlFor="transferReference" className="form-label">
                        Amount
                      </label>
                      <div className="form-control">
                        {tranRefPopup?.data?.amount}
                      </div>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="transferReference" className="form-label">
                        Transaction Reference
                      </label>
                      <Field
                        type="text"
                        name="transferReference"
                        className="form-control"
                        placeholder="transaction reference"
                      />
                      <ErrorMessage
                        name="transferReference"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={isSubmitting}
                    >
                      Submit
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const BankPopup = () => {
    return (
      <div
        className={`modal ${bankPopup?.status ? "show" : "hide"}`}
        id="calendar-price"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Bank Details
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setBankPopup({ status: false, data: {} })}
              ></button>
            </div>
            <div className="modal-body">
              <div className="bsnkdetails">
                <ul>
                  <li>
                    <b>Bank Account No.</b>{" "}
                    <span>{bankPopup?.data?.accountNumber}</span>
                  </li>
                  <li>
                    <b>Bank Name </b> <span>{bankPopup?.data?.bankName}</span>
                  </li>
                  <li>
                    <b>Bank Identifier Code </b>{" "}
                    <span>{bankPopup?.data?.bicCode}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <BankPopup />
      <TransferReferencePopup />
      <div className={commonStyles["vendor-listing"]}>
        <div className="d-flex justify-content-between align-items-center">
          <ul className="paginaion">
            <li>
              <Link to="/">Dashboard</Link>
            </li>
            <li className="active">
              <Link to={APP_ROUTES.VIEW_INVOICES}>Invoices</Link>
            </li>
          </ul>
        </div>
        <div className={commonStyles["filter-main"]}>
          <div className={commonStyles["filter-left"]}>
            <div className="form-group">
              <span className={tableStyles["search-c"]}>
                <img src={SearchIcon} alt="search" />
              </span>
              <input
                type="invoiceNoSearch"
                placeholder="Search by Invoice no"
                onChange={(e) => setInvoiceNoSearch(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") debouncePayoutsList();
                }}
                value={invoiceNoSearch}
                className="me-2"
              />
              {invoiceNoSearch?.length ? (
                <button
                  className={tableStyles["search-button"]}
                  onClick={clearResult}
                >
                  <i className="fa fa-eraser" />
                </button>
              ) : null}
            </div>
          </div>
        </div>

        <div className={tableStyles["listing-table"]}>
          <div className={tableStyles["listing-table-scrl"]}>
            <table cellPadding={0} cellSpacing={0}>
              <thead>
                <tr>
                  <th></th>
                  <th>Inovice No.</th>
                  {user.userType === USER_TYPE.ADMIN ? (
                    <th>Vendor Name</th>
                  ) : null}
                  {user.userType === USER_TYPE.VENDOR ? <th>Status</th> : null}
                  <th>Amount</th>
                  <th>Invoiced At</th>
                  <th>Transaction Refernce</th>
                  <th>Bank Details</th>
                  {user.userType === USER_TYPE.ADMIN && (
                    <th className="text-center">Update Payouts</th>
                  )}
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {invoiceListLoader ? (
                  <tr className={tableStyles.noresult}>
                    <td colSpan={user.userType === USER_TYPE.ADMIN ? 8 : 7}>
                      {MESSAGES.LOADING}
                    </td>
                  </tr>
                ) : null}
                {!invoiceListLoader && invoices?.length === 0 ? (
                  <tr className={tableStyles.noresult}>
                    <td colSpan={user.userType === USER_TYPE.ADMIN ? 8 : 7}>
                      {MESSAGES.PAYOUTS.NO_PAYOUTS_FOUND}
                    </td>
                  </tr>
                ) : null}
                {!invoiceListLoader &&
                  invoices?.map((item, index) => (
                    <tr key={item._id}>
                      <td>{++index}</td>
                      <td>{item.invoiceNumber}</td>
                      {user.userType === USER_TYPE.VENDOR ? (
                        <td>{INVOICE_STATUS[item.status]}</td>
                      ) : null}
                      {user.userType === USER_TYPE.ADMIN ? (
                        <td>
                          {item.vendorData && item.vendorData.firstName}{" "}
                          {item.vendorData && item.vendorData.lastName}
                        </td>
                      ) : null}
                      <td>
                        {item.currency} {item.amount}{" "}
                      </td>
                      <td>{moment(item.createdAt).format("YYYY-MM-DD")}</td>
                      <td>{item?.transactionReference || "-"}</td>
                      <td>
                        <i
                          className="fas fa-info-circle"
                          onClick={() => bankDetails(item.bankAccountId)}
                        ></i>
                      </td>
                      {user.userType === USER_TYPE.ADMIN && (
                        <td className="text-center" style={{ width: "170px" }}>
                          {item.status === 1 ? (
                            <p
                              onClick={() =>
                                setTranRefPopup({
                                  status: true,
                                  data: {
                                    invoiceId: item._id,
                                    amount: `${item.currency} ${item.amount}`,
                                  },
                                })
                              }
                              className={styles.pay}
                            >
                              Pay
                            </p>
                          ) : (
                            <p className={styles.paid}>Paid</p>
                          )}
                        </td>
                      )}
                      <td>
                        <Link to={`/payouts/${item._id}`}>
                          <i className="fas fa-eye"></i>
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          <Pagination count={invoicesCount} setPagination={setPagination} />
        </div>
      </div>
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  viewInvoices: (query, userType, loader) =>
    dispatch(AdminInvoiceAction.getInvoiceList(query, userType, loader)),
  updatePayouts: (payload, loader, query, userType) =>
    dispatch(
      AdminInvoiceAction.markPayoutSuccess(payload, loader, query, userType)
    ),
  specificPayoutCard: (id, loader) =>
    dispatch(VendorPayoutAction.specificPayoutCard(id, loader)),
});

export default connect(null, mapDispatchToProps)(ViewInvoices);
