import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./index.css";
import "react-markdown-editor-lite/lib/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "react-datepicker/dist/react-datepicker.css";
import "react-calendar/dist/Calendar.css";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import store from "./store";
import { Toaster } from "react-hot-toast";

ReactDOM.render(
    <BrowserRouter>
        <Provider store={store}>
            <ToastContainer />
            <Toaster
  position="top-right"
  reverseOrder={false}
/>
            <App />
        </Provider>
    </BrowserRouter>,
    document.getElementById("root")
);
