import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { Field, useFormik, FormikProvider } from "formik";

import { CategorySchema } from "../../../../schemas";
import { AdminCategoryAction } from "../../../../store/admin/thunk";
import { uploadImage } from "../../../../utils/imageHelper";

const AddCategory = ({ loader, create, update, specificCategory }) => {
  const navigate = useNavigate();
  const params = useParams();
  const [image, setImage] = useState({});
  const [imagePreview, setImagePreview] = useState(false);
  const [imageErr, setImageErr] = useState("");
  const {
    categories: { category },
  } = useSelector((state) => state.admin);

  const categoriesFormik = useFormik({
    initialValues: { name: "", type: "", vat: "", image: null },
    validationSchema: CategorySchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      let payload = values;
      if (image) {
        payload.image = image?.data?.fileId;
      }

      if (params.id) {
        payload = {
          category_id: params.id,
          name: values.name,
          type: values.type,
          vat: values.vat,
        };

        if (image) {
          payload.image = image?.data?.fileId;
        }

        update(payload, loader, navigate);
      } else create(payload, loader, navigate);
    },
  });

  useEffect(() => {
    if (params.id) {
      specificCategory(params.id, loader);
      setImage(category.image);
    }
  }, [params.id]);

  useEffect(() => {
    if (category.id) {
      categoriesFormik.setValues({
        name: category.name,
        vat: category.vat,
        type: category.type,
        image: category.image,
      });
    }
  }, [category]);

  const uploadCategoryImage = async (event) => {
    setImage({});
    const file = event.currentTarget.files[0];

    // Define size limit (2 MB) and allowed file types
    const maxSize = 2 * 1024 * 1024; // 2 MB in bytes
    const allowedTypes = ["image/jpg", "image/jpeg", "image/png"];

    // Check file type and size
    if (file) {
      if (!allowedTypes.includes(file.type)) {
        setImageErr(true);
        setImageErr("Only JPEG, JPG, and PNG formats are allowed.");
        return;
      }

      if (file.size > maxSize) {
        setImageErr("File size should not exceed 1MB.");
        return;
      }

      // Set the image field in Formik
      categoriesFormik.setFieldValue("image", file);

      // Prepare the form data and upload the image
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", 1);

      try {
        const image = await uploadImage(formData);
        setImage(image);
        setImageErr("");
      } catch (error) {
        setImageErr("An error occurred while uploading the image.");
        return;
      }
    }
  };

  return (
    <>
      <div className="edit-users-sc">
        <FormikProvider value={categoriesFormik}>
          <div className="form-group">
            <label>Category Image</label>
            <div className="ImageEdit">
              {(category?.image || image?.data?.fileUrl) && <label onClick={()=> {
                if(category?.image || image?.data?.fileUrl)  setImagePreview(true)
                }}>
                <i className="fas fa-eye"></i>
              </label>}
              
              <span>
                <i className="far fa-image"></i>
              </span>
              <input
                type="file"
                name="image"
                accept="image/*"
                onChange={(event) => {
                  uploadCategoryImage(event);
                }}
              />
              {image?.data?.fileUrl ? (
                <img src={image?.data?.fileUrl} alt={image?.data?.fileUrl} />
              ) : (
                category?.image && (
                  <img src={category?.image} alt={category?.image} />
                )
              )}
            </div>
            {(categoriesFormik.errors.image &&
              categoriesFormik.touched.image) ||
            imageErr ? (
              <div className="error">
                {imageErr ? imageErr : categoriesFormik.errors.image}
              </div>
            ) : null}
          </div>
          <div className="form-group">
            <label>Category name</label>
            <Field name="name" placeholder="Category name" />
            {categoriesFormik.errors.name && categoriesFormik.touched.name ? (
              <div className="error">{categoriesFormik.errors.name}</div>
            ) : null}
          </div>
          <div className="form-group">
            <label>Select type</label>
            <Field as="select" name="type">
              <option value="">click to select</option>
              <option value="PROPERTY">Property</option>
              <option value="CAR">Car</option>
              <option value="TOUR">Tour</option>
            </Field>
            {categoriesFormik.errors.type && categoriesFormik.touched.type ? (
              <div className="error">{categoriesFormik.errors.type}</div>
            ) : null}
          </div>
          <div className="form-group">
            <label>Vendor Commission</label>
            <Field
              type="number"
              min="1%"
              max="100%"
              name="vat"
              placeholder="Vendor Commission"
            />
            {categoriesFormik.errors.vat && categoriesFormik.touched.vat ? (
              <div className="error">{categoriesFormik.errors.vat}</div>
            ) : null}
          </div>

          <button onClick={categoriesFormik.handleSubmit} type="button">
            {params.id ? "Update" : "Add"}
          </button>
        </FormikProvider>
      </div>
{imagePreview  && 
<div className="modal show" style={{ display: 'block' }}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Category Image
              </h5>
              <button
                type="button"
                class="btn-close"
                aria-label="Close"
                onClick={()=> setImagePreview(false)}
              ></button>
            </div>
            <div class="modal-body">
              <div className="image-preview">
                {image?.data?.fileUrl ? (
                  <img
                    src={image?.data?.fileUrl}
                    alt={image?.data?.fileUrl}
                  />
                ) : (
                  category?.image && (
                    <img src={category?.image} alt={category?.image} />
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
}
      

    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  create: (payload, loader, navigate) =>
    dispatch(AdminCategoryAction.createCategory(payload, loader, navigate)),
  update: (payload, loader, navigate) =>
    dispatch(AdminCategoryAction.update(payload, loader, navigate)),
  specificCategory: (payload, loader) =>
    dispatch(AdminCategoryAction.specificCategory(payload, loader)),
});

export default connect(null, mapDispatchToProps)(AddCategory);
