
import {Axios} from "../../../lib";
import { API_ENDPOINTS, API_URL, MESSAGES, TIMEZONE_URL, TOAST_TYPE } from "../../../constants";
import COMMON_ACTIONS from "../actions";
import { APP_ROUTES } from "../../../routes";
import { Toaster } from "../../../components";

const getUserDetails = (navigate, loader) => async (dispatch) => {
    try {
        dispatch({ type: COMMON_ACTIONS.LOADING_USER_DETAILS, loading: true });
        const response = await Axios.get(API_ENDPOINTS.GET_USER_DETAILS, {}, loader);

        if (!response.status) {
            localStorage.clear();
            navigate(APP_ROUTES.LOGIN);
        }
        else {
            dispatch({ type: COMMON_ACTIONS.USER_DETAILS_SUCCESS, user: response.data, loading: false });
            dispatch({ type: COMMON_ACTIONS.LOADING_USER_DETAILS, loading: false });
        }
    }
    catch (err) {
        dispatch({ type: COMMON_ACTIONS.LOADING_USER_DETAILS, loading: false });
    }
};

const propertyList = (query, loader) => async (dispatch) => {
    try {
        dispatch({ type: COMMON_ACTIONS.REQUEST_PROPERTY_LIST, status: true });
        const { data } = await Axios.get(API_ENDPOINTS.PROPERTY_CRUD, query, loader);

        dispatch({ type: COMMON_ACTIONS.PROPERTIES_LIST_SUCCESS, properties: data.properties, count: data.count });
        dispatch({ type: COMMON_ACTIONS.REQUEST_PROPERTY_LIST, status: false });
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.SOMETHING_WENT_WRONG);
    }
    
};

const carsList = (query, loader) => async (dispatch) => {
    try {
        dispatch({ type: COMMON_ACTIONS.REQUEST_CAR_LIST, status: true });
        const response = await Axios.get(API_ENDPOINTS.LIST_CARS, query, loader);

        dispatch({ type: COMMON_ACTIONS.CAR_LIST_SUCCESS, cars: response.data.cars, count: response.data.count });
        dispatch({ type: COMMON_ACTIONS.REQUEST_CAR_LIST, status: false });
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.SOMETHING_WENT_WRONG);
    }
};

const categoriesList = (query, loader) => async (dispatch) => {
    try {
        const response = await Axios.get(API_ENDPOINTS.LIST_CATEGORIES, query, loader);

        dispatch({ type: COMMON_ACTIONS.CATEGORY_LIST_SUCCESS, categories: response.data.categories, count: response.data.count });
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.SOMETHING_WENT_WRONG);
    }
};

const subCategoriesList = (categoryId, loader) => async (dispatch) => {
    try {
        const response = await Axios.get(`v1/category/${categoryId}`, {}, loader);

        dispatch({ type: COMMON_ACTIONS.SUB_CATEGORY_LIST_SUCCESS, subCategories: response.data.sub_categories });
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.SOMETHING_WENT_WRONG);
    }
};

const specificCar = (query, loader) => async (dispatch) => {
    try {
        dispatch({ type: COMMON_ACTIONS.REQUEST_SPECIFIC_CAR, status: true });
        const response = await Axios.get(`v1/car/${query.id}`, {}, loader);

        dispatch({ type: COMMON_ACTIONS.SET_IMAGES, key: "selectedImage", value: response.data.images });
        dispatch({ type: COMMON_ACTIONS.SET_IMAGES, key: "images", value: response.data.images });

        dispatch({ type: COMMON_ACTIONS.SET_SPECIFIC_CAR, car: response.data });
        dispatch({ type: COMMON_ACTIONS.REQUEST_SPECIFIC_CAR, status: false });
    }
    catch (err) {
        dispatch({ type: COMMON_ACTIONS.REQUEST_SPECIFIC_CAR, status: false });
    }
};

const specificProperty = (query, loader) => async (dispatch) => {
    try {
        dispatch({ type: COMMON_ACTIONS.REQUEST_SPECIFIC_PROPERTY, status: true });
        const response = await Axios.get(API_ENDPOINTS.SPECIFIC_PROPERTY, query, loader);

        let property = response.data;
        property.commonAmenities = response.data.amenities.filter(item => !item.isSpecial);
        property.specialAmmenities = response.data.amenities.filter(item => item.isSpecial);

        dispatch({ type: COMMON_ACTIONS.SET_SPECIFIC_PROPERTY, property: property });
        dispatch({ type: COMMON_ACTIONS.REQUEST_SPECIFIC_PROPERTY, status: false });
    }
    catch (err) {
        dispatch({ type: COMMON_ACTIONS.REQUEST_SPECIFIC_PROPERTY, status: false });
        dispatch({ type: COMMON_ACTIONS.SET_SPECIFIC_PROPERTY, property: {} });
    }
};

const toursList = (query, loader) => async (dispatch) => {
    try {
        dispatch({ type: COMMON_ACTIONS.REQUEST_TOUR_LIST, status: true });
        const response = await Axios.get(API_ENDPOINTS.TOUR_CRUD, query, loader);

        dispatch({ type: COMMON_ACTIONS.TOURS_LIST_SUCCESS, tours: response.data.tour, count: response.data.count });
        dispatch({ type: COMMON_ACTIONS.REQUEST_TOUR_LIST, status: false });
    }
    catch (err) {
        dispatch({ type: COMMON_ACTIONS.REQUEST_TOUR_LIST, status: false });
    }
};

const recommendedProperties = (query, loader) => async () => {
    try {
        const response = await Axios.get("v1/property/recommended", query, loader);
        
        return response.data;
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.PROPERTY.RECOMMENDED_LIST_ERROR);
    }
};


const specificTour = (slug, currency, loader) => async (dispatch) => {
    try {
        const response = await Axios.get(`v1/tour/${slug}/${currency}`, {}, loader);

        dispatch({ type: COMMON_ACTIONS.SET_SPECIFIC_TOUR, tour: response.data });
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.SOMETHING_WENT_WRONG);
    }
};

const notificationsList = (params, loader, replace = false) => async (dispatch) => {
    try {
        const response = await Axios.get(API_ENDPOINTS.LIST_NOTIFICATIONS, params, loader);

        dispatch({
            type: replace ? COMMON_ACTIONS.SET_NOTIFICATIONS: COMMON_ACTIONS.SET_NOTIFICATIONS_TAB,
            notifications: response.data.notifications,
            notificationCount: response.data.count,
            unreadNotificationsCount: response.data.notificationCount,
            replace,
        });
    }
    catch (err) {
        // Toaster(TOAST_TYPE.ERROR, MESSAGES.SOMETHING_WENT_WRONG);
    }
};

const getCurrency = (loader) => async (dispatch) => {
    const response = await Axios.getRequest(API_URL.CURRENCY_URL, loader);

    if (response.status === 200) dispatch({ type: COMMON_ACTIONS.SET_CURRENCY, currency: response.data });
};

const getCurrentLocation = (position, loader) => async (dispatch) => {
    const response = await Axios.getRequest(`${TIMEZONE_URL}/${position.coords.latitude},${position.coords.longitude}`, loader);

    let payload = {
        type: COMMON_ACTIONS.SET_USER_LOCATION,
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
    };

    if (response.data && response.data.timezone_id) {
        payload.timezone = response.data.timezone_id;
    }

    dispatch(payload);
}


export {
    getUserDetails,
    propertyList,
    carsList,
    categoriesList,
    subCategoriesList,
    specificCar,
    specificProperty,
    toursList,
    specificTour,
    notificationsList,
    getCurrency,
    getCurrentLocation,
    recommendedProperties
};