import React, { useEffect, useState } from "react";
import ApexCharts from "react-apexcharts";
import { Axios } from "../../lib";

const months = {
    0: "Jan",
    1: "Feb",
    2: "Mar",
    3: "Apr",
    4: "May",
    5: "Jun",
    6: "Jul",
    7: "Aug",
    8: "Sep",
    9: "Oct",
    10: "Nov",
    11: "Dec",
};

function Linechart({ data, loader ,type = "PROPERTY" }) {
    const [year, setYear] = useState(new Date().getFullYear());
    const [adminDash, setAdminDash] = useState([]);
    const [chartData, setChartData] = useState({
        series: [{ name: "Revenue", data: [] }],
        options: {
            chart: {
                type: "bar",
                animations: { enabled: true },
                toolbar: { show: false },
            },
            theme: { mode: "light" },
            plotOptions: {
                bar: {
                    borderRadius: 5, // Rounded corners for bars
                    columnWidth: "50%", // Adjust width
                },
            },
            xaxis: {
                categories: [],
                labels: { show: true },
            },
            yaxis: {
                labels: {  formatter: (value) => `$${value.toLocaleString()}`, },
                min: 0,
                max: 9,
                tickAmount: 9,
            },
            grid: {
                strokeDashArray: 5, // Dashed grid lines for better visibility
            },
            tooltip: {
                enabled: true,
                y: {
                    formatter: (value) => `$${value.toLocaleString()}`,
                },
            },
            fill: {
                type: "gradient", // Adds smooth gradient fill
                gradient: {
                    shadeIntensity: 0.5,
                    opacityFrom: 0.9,
                    opacityTo: 0.3,
                },
            },
            dataLabels: { enabled: false },
            legend: { show: true, position: "top" },
        },
    });
 
    const adminDashboardgraph = async () => {
        try {
            const { status, data } = await Axios.get(
                `v1/revenue/monthly/graph?type=${type}&graphYear=${year}`,
                {},
                loader
            );
            if (status) {
                setAdminDash(data);
                const monthsData = data.map((val) => months[val.month]);
                const totalAmountData = data.map((val) => val.totalAmount);
 
                const maxYValue = Math.max(...totalAmountData, 10) * 1.2; // Dynamic max Y-axis
 
                setChartData({
                    series: [{ name: "Revenue", data: totalAmountData }],
                    options: {
                        ...chartData.options,
                        xaxis: { ...chartData.options.xaxis, categories: monthsData },
                        yaxis: { ...chartData.options.yaxis, max: maxYValue },
                    },
                });
            }
        } catch (error) {
            console.error("ERROR-->", error);
        }
    };
 
    useEffect(() => {
        adminDashboardgraph();
    }, [year, type]);
 
    return (
        <div>
            <select value={year} onChange={(e) => setYear(e.target.value)}>
                {Array.from({ length: 5 }, (_, i) => (
                    <option key={i} value={2023 + i}>
                        {2023 + i}
                    </option>
                ))}
            </select>
            <ApexCharts
                options={chartData.options}
                series={chartData.series}
                type="bar"
                height={350}
            />
        </div>
    );
}
 
export default Linechart;
 