import { useFormik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import { Button, Modal } from 'antd';

import {
    CALENDAR_DRAGGING_TYPE,
    OPEN_CLOSE_BOOKING_TYPES,
} from "../../../../constants/calendar";
import { CalendarPriceSchema, CalendarNightSchema } from "../../../../schemas";
import styles from "../style.module.css";
import Calendar from "react-calendar";

const PricePopup = (props) => {
    const today = new Date();
    const [startDate, setStartDate] = useState(
        props?.priceDates?.startDate
            ? moment(props.priceDates.startDate).format("DD MMM YYYY")
            : null
    );

    const [endDate, setEndDate] = useState(
        props?.priceDates?.endDate
            ? moment(props.priceDates.endDate).format("DD MMM YYYY")
            : null
    );

    useEffect(() => {
        setStartDate(
            props?.priceDates?.startDate
                ? moment(props.priceDates.startDate).format("DD MMM YYYY")
                : null
        );

        setEndDate(
            props?.priceDates?.endDate
                ? moment(props.priceDates.endDate).format("DD MMM YYYY")
                : null
        );
    }, [props.priceDates]);

    useEffect(() => {
        if (startDate && endDate)
            props.setPriceDragDate({
                startDate: startDate,
                endDate: endDate,
            });
    }, [startDate, endDate]);

    const disablePastDates = ({ date, view }) => {
        return date < today;
    };

    const pricePopupFormik = useFormik({
        initialValues: { price: 0, openCloseStatus: null, night: 0 },
        validationSchema: CalendarPriceSchema,
        onSubmit: (values) => {
            // Create a new object with just the price and night values
            const updateValues = {
                price: values.price,
                night: values.night
            };

            console.log("Current form values:", values);
            console.log("Current property bookable status:", props.priceDetails.isBookable);

            // Only include the status if it's explicitly different from the current status
            let statusToUpdate = undefined;

            // Check if the radio selection is different from the current booking status
            const statusChanged = values.openCloseStatus !== props.priceDetails.isBookable;

            if (statusChanged) {
                console.log(`Status changed from ${props.priceDetails.isBookable} to ${values.openCloseStatus}`);
                statusToUpdate = values.openCloseStatus;
            } else {
                console.log("Status unchanged, preserving current status");
            }

            // Pass only the values we want to update, and status only if it changed
            props.updatePrice(updateValues, statusToUpdate);
        },
        enableReinitialize: true,
    });

    useEffect(() => {
        if (pricePopupFormik.values.openCloseStatus !== undefined) {
            console.log("Form booking status changed to:", pricePopupFormik.values.openCloseStatus);
        }
    }, [pricePopupFormik.values.openCloseStatus]);

    const nightsPopupFormik = useFormik({
        initialValues: { night: 0 },
        onSubmit: (values) => {
            props.updatePrice(values.night);
        },
        enableReinitialize: true,
        validationSchema: CalendarNightSchema,
    });

    useEffect(() => {
        if (props.priceDetails && typeof props.priceDetails.minNight == "number") {
            nightsPopupFormik.setValues({
                night: props.priceDetails.minNight,
            });
        }
        if (props.priceDetails && typeof props.priceDetails.price == "number") {
            console.log("Setting initial booking status:", props.priceDetails.isBookable);

            pricePopupFormik.setValues({
                price: parseFloat(props.priceDetails.price),
                openCloseStatus: props.priceDetails.isBookable || OPEN_CLOSE_BOOKING_TYPES.OPEN,
                night: props.priceDetails.minNight,
            });
        }
    }, [props.priceDetails]);

    return (
        <Modal
            title={
                <h5>
                    Change{" "}
                    {props?.priceDetails?.dragType ===
                        CALENDAR_DRAGGING_TYPE.NIGHT_DRAGGING
                        ? "Length of stay"
                        : "Price"}
                </h5>
            }
            open={props.show}
            onCancel={() => props.handlePriceModal(false)}
            footer={[
                <Button
                    key="submit"
                    type="button"
                    className="InactiveBtn"
                    onClick={(event) =>
                        props.priceDetails.dragType ===
                            CALENDAR_DRAGGING_TYPE.PRICE_DRAGGING
                            ? pricePopupFormik.handleSubmit(event)
                            : nightsPopupFormik.handleSubmit(event)
                    }
                >
                    Update
                </Button>
            ]}
            centered
            width={700}
        >
            <div className="px-0">
                <div className="px-3 pt-2">
                    <p className={styles.categoryName}>
                        {props?.selectedProperty?.category_id?.name} (Room ID:{" "}
                        {props?.selectedProperty?.unitNumber})
                    </p>
                    <p className={styles.rate}>Standard Rate</p>
                    <p className={styles.priceDate}>
                        {moment(startDate).format("DD MMM YYYY")}{" "}
                        {!moment(startDate).isSame(moment(endDate))
                            ? `to ${moment(endDate).format("DD MMM YYYY")}`
                            : ""}
                    </p>
                    {startDate && endDate ? (
                        <div className="d-flex flex-column flex-md-row">
                            <Calendar
                                value={startDate}
                                onChange={setStartDate}
                                tileDisabled={disablePastDates}
                            />
                            <Calendar
                                value={endDate}
                                onChange={setEndDate}
                                tileDisabled={disablePastDates}
                            />
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
                {props?.priceDetails?.dragType ===
                    CALENDAR_DRAGGING_TYPE.PRICE_DRAGGING ? (
                    <Fragment>
                        <div className="px-3 d-flex pt-2">
                            <label className={`rad ${styles.openCloseStatus}`}>
                                <input
                                    type="radio"
                                    checked={
                                        pricePopupFormik.values.openCloseStatus ===
                                        OPEN_CLOSE_BOOKING_TYPES.OPEN
                                    }
                                    name="radio"
                                    onChange={() => {
                                        console.log("Setting booking status to OPEN");
                                        pricePopupFormik.setFieldValue(
                                            "openCloseStatus",
                                            OPEN_CLOSE_BOOKING_TYPES.OPEN
                                        );
                                    }}
                                />
                                <span className="checkmark"></span>
                                Vacant
                            </label>
                            <label className={`rad ${styles.openCloseStatus}`}>
                                <input
                                    type="radio"
                                    checked={
                                        pricePopupFormik.values.openCloseStatus ===
                                        OPEN_CLOSE_BOOKING_TYPES.CLOSE
                                    }
                                    name="radio"
                                    onChange={() => {
                                        console.log("Setting booking status to CLOSE");
                                        pricePopupFormik.setFieldValue(
                                            "openCloseStatus",
                                            OPEN_CLOSE_BOOKING_TYPES.CLOSE
                                        );
                                    }}
                                />
                                <span className="checkmark"></span>
                                Close
                            </label>
                        </div>
                        <div className="px-3">
                            {pricePopupFormik.errors.openCloseStatus ? (
                                <p className="text-danger mb-0">
                                    {pricePopupFormik.errors.openCloseStatus}
                                </p>
                            ) : null}
                        </div>
                        <div className="px-3 py-2">
                            <label>Price</label>
                            <input
                                type="text"
                                name="price"
                                id="price"
                                className="form-control px-3 py-2"
                                value={pricePopupFormik.values.price}
                                onChange={pricePopupFormik.handleChange}
                                onBlur={pricePopupFormik.handleBlur}
                            />
                            {pricePopupFormik.touched.price &&
                                pricePopupFormik.errors.price ? (
                                <p className="text-danger mb-0">
                                    {pricePopupFormik.errors.price}
                                </p>
                            ) : null}
                        </div>
                        <div className="px-3 py-2">
                            <label>Maximum length of stay</label>
                            <input
                                type="text"
                                name="night"
                                className="form-control px-3 py-2"
                                value={pricePopupFormik.values.night}
                                onChange={pricePopupFormik.handleChange}
                                onBlur={pricePopupFormik.handleBlur}
                            />
                            {pricePopupFormik.touched.night &&
                                pricePopupFormik.errors.night ? (
                                <p className="text-danger mb-0">
                                    {pricePopupFormik.errors.night}
                                </p>
                            ) : null}
                        </div>
                    </Fragment>
                ) : (
                    <Fragment>
                        {/* <div className="modal-body">
                  <label>Minimum length of stay</label>
                  <input
                    type="text"
                    name="night"
                    className="form-control px-3 py-2"
                    value={nightsPopupFormik.values.night}
                    onChange={nightsPopupFormik.handleChange}
                    onBlur={nightsPopupFormik.handleBlur}
                  />
                  {nightsPopupFormik.touched.night &&
                  nightsPopupFormik.errors.night ? (
                    <p className="text-danger mb-0">
                      {nightsPopupFormik.errors.night}
                    </p>
                  ) : null}
                </div> */}
                    </Fragment>
                )}
            </div>
        </Modal>
    );
};

export default PricePopup;
