import React, { Fragment, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { debounce } from "lodash";

import { VendorPayoutAction } from "../../../store/vendor/thunk";
import { CATEGORY_TYPE, MESSAGES, PAYMENT_TYPE, TOAST_TYPE } from "../../../constants";
import { Button, Pagination, Toaster } from "../../../components";
import styles from "./style.module.css";
import tableStyles from "../../../components/Table/style.module.css";
import commonStyles from "../../Common/styles/common.module.css";
import { SearchIcon } from "../../../assets/images";
import { APP_ROUTES } from "../../../routes";
import { useFormik } from "formik";
import VENDOR_ACTIONS from "../../../store/vendor/actions";

const Payouts = ({ list, loader, generateInvoice, listPayoutCards }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [payoutType, setPayoutType] = useState(CATEGORY_TYPE.PROPERTY);
    const [pagination, setPagination] = useState({ offset: 0, limit: 10 });
    const [amount, setAmount] = useState({ totalVendorAmount: 0, rentVIPAmount: 0, overallAmount: 0 });
    const [isGenerateInvoice, setGenerateInvoice] = useState(false);
    const [selectedBookings, setSelectedBookings] = useState([]);

    const { payouts: { payouts, payoutLoader, payoutsCount, payoutCards } } = useSelector((state) => state.vendor);

    const [bookingNoSearch, setBookingNoSearch] = useState("");
    const [propertyNameSearch, setPropertyNameSearch] = useState("");

    const handlePayoutTypeChange = (type) => {
        setPayoutType(type);
    };

    const getPayouts = (offset = pagination.offset) => {
        const query = {
            type: payoutType,
            offset,
            limit: pagination.limit,
        };

        if (bookingNoSearch && bookingNoSearch.length) query.bookingNumber = bookingNoSearch;
        else if (propertyNameSearch && propertyNameSearch.length) query.searchString = propertyNameSearch;

        list(query, loader, selectedBookings);
    };

    const debouncePayoutsList = debounce(() => getPayouts(0), 500);

    const bankAccountFormik = useFormik({
        initialValues: {
            bankAccountId: '',
        },
        onSubmit: (values) => {
            generateInvoice(loader, payoutType, pagination.limit, values.bankAccountId, []);
            bankAccountFormik.resetForm();
            setGenerateInvoice(false);

            setSelectedBookings([]);
        },
    });

    const clearResult = () => {
        setBookingNoSearch("");
        setPropertyNameSearch("");
        getPayouts(0);
    };

    const handleInvoiceChange = (event) => {
        let tempSelected = selectedBookings;
        let index = tempSelected.findIndex(item => item === event.target.value);

        if (index >= 0) tempSelected = selectedBookings.filter(item => item !== event.target.value);
        else tempSelected.push(event.target.value);

        const tempPayouts = payouts;
        const tempPayoutsIndex = tempPayouts.findIndex(item => item._id === event.target.value);

        tempPayouts[tempPayoutsIndex].selected = !tempPayouts[tempPayoutsIndex].selected;

        dispatch({ type: VENDOR_ACTIONS.SET_PAYOUTS, payouts: tempPayouts, count: payoutsCount });
        setSelectedBookings(tempSelected);
    };

    const generateNewInvoice = () => {
        if (!selectedBookings.length) {
            Toaster(TOAST_TYPE.ERROR, "Select atleast one Payout");
        }
        else setGenerateInvoice(true);
    };

    useEffect(() => {
        getPayouts();

    }, [payoutType, pagination]);

    useEffect(() => {
        let totalVendorAmount = 0;
        let rentVIPAmount = 0;
        let overallAmount = 0;


        for (const item of payouts) {
            totalVendorAmount += item.amountPerDay;
            rentVIPAmount += item.serviceCharge;
            overallAmount += item.settlementAmount;
        }

        setAmount({ totalVendorAmount, rentVIPAmount, overallAmount });

    }, [payouts]);

    useEffect(() => {
        listPayoutCards(loader, { cardStatus: 2 });
    }, [])

    return (
        <div className={commonStyles["vendor-listing"]}>
            <div className="d-flex justify-content-between align-items-center">
                <ul className="paginaion">
                    <li>
                        <Link to="/">Dashboard</Link>
                    </li>
                    <li className="active">
                        <Link to={APP_ROUTES.VIEW_PAYOUTS_SETTINGS}>Payouts</Link>
                    </li>
                </ul>
                <div className='d-flex'>
                    <Link to={APP_ROUTES.VIEW_PAYOUTS_SETTINGS}>
                        <button className={styles.generateInvoicedata}>
                            Payout Settings
                        </button>
                    </Link>
                    <Link to={APP_ROUTES.VIEW_INVOICES}>
                        <button className={styles.generateInvoicedata}>
                            Invoice History
                        </button>
                    </Link>
                    <button onClick={generateNewInvoice} className={styles.generateInvoice}>
                        Generate Invoice
                    </button>
                </div>
            </div>

            <div className={commonStyles["filter-main"]}>
                <div className={commonStyles["filter-left"]}>

                    <div className="form-group">
                        <span className={tableStyles["search-c"]}>
                            <img src={SearchIcon} alt="search" />
                        </span>
                        <input
                            type='bookingNoSearch'
                            placeholder='Booking No'
                            onChange={(e) => setBookingNoSearch(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") debouncePayoutsList();
                            }}
                            value={bookingNoSearch}
                            className='me-2'
                        />
                    </div>

                    <div className='form-group'>
                        <span className={tableStyles["search-c"]}>
                            <img src={SearchIcon} alt="search" />
                        </span>

                        <input
                            type='text'
                            name="propertyNameSearch"
                            placeholder={`${payoutType === CATEGORY_TYPE.PROPERTY ? "Property" : payoutType === CATEGORY_TYPE.TOUR ? "Tour" : "Car"} name`}
                            onChange={(e) => setPropertyNameSearch(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") debouncePayoutsList();
                            }}
                            value={propertyNameSearch}
                            className='me-2'
                        />

                        {(propertyNameSearch.length || bookingNoSearch.length) ?
                            <button className="search-button" onClick={clearResult}>
                                <i className="fa fa-eraser" />
                            </button>
                            :
                            null
                        }
                    </div>
                </div>
            </div>

            <div className={styles.tabPanel}>
                <div
                    className={`${styles.tab} me-2 ${payoutType === CATEGORY_TYPE.PROPERTY ? styles.activeTab : ""}`}
                    onClick={() => handlePayoutTypeChange(CATEGORY_TYPE.PROPERTY)}
                >
                    Property
                </div>
                <div
                    className={`${styles.tab} me-2 ${payoutType === CATEGORY_TYPE.CAR ? styles.activeTab : ""}`}
                    onClick={() => handlePayoutTypeChange(CATEGORY_TYPE.CAR)}
                >
                    Car
                </div>
                <div
                    className={`${styles.tab} ${payoutType === CATEGORY_TYPE.TOUR ? styles.activeTab : ""}`}
                    onClick={() => handlePayoutTypeChange(CATEGORY_TYPE.TOUR)}
                >
                    Tour
                </div>
            </div>

            <div className={tableStyles["listing-table"]}>
                <div className={tableStyles["listing-table-scrl"]}>
                    <table cellPadding={0} cellSpacing={0}>
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th>Booking No.</th>
                                <th>{payoutType === CATEGORY_TYPE.PROPERTY ? "Property" : payoutType === CATEGORY_TYPE.TOUR ? "Tour" : "Car"} Name</th>
                                <th>{payoutType === CATEGORY_TYPE.PROPERTY ? "Check In" : payoutType === CATEGORY_TYPE.CAR ? "Pick up Date" : "Date"}</th>
                                <th
                                    className={payoutType === CATEGORY_TYPE.TOUR ? "d-none" : ''}>
                                    {payoutType === CATEGORY_TYPE.PROPERTY ? "Check Out" : payoutType === CATEGORY_TYPE.CAR ? "Drop Date" : "Car"}
                                </th>
                                <th>Payment Mode</th>
                                <th>Vendor Amount</th>
                                <th>Rent VIP Charge</th>
                                <th>Overall Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {payoutLoader ? <tr className={tableStyles.noresult}><td colSpan={10}>{MESSAGES.LOADING}</td></tr> : null}
                            {!payoutLoader && payouts.length === 0 ? <tr className={tableStyles.noresult}><td colSpan={10}>{MESSAGES.PAYOUTS.NO_PAYOUTS_FOUND}</td></tr> : null}
                            {!payoutLoader && payouts.map((item, index) => (
                                <tr key={item._id}>
                                    <td>{++index}</td>
                                    <td>
                                        <input type="checkbox" value={item._id} onChange={handleInvoiceChange} checked={item.selected} />
                                    </td>
                                    <td onClick={() => navigate(`/propertyBookings/${item.bookingData._id}`)} className={styles.bookingNo}>{item.bookingData.bookingNumber}</td>
                                    <td>{item.bookingData.bookingCategoryName} </td>
                                    {payoutType === CATEGORY_TYPE.PROPERTY ?
                                        <Fragment>
                                            <td>{moment(item.bookingData.checkIn).format("YYYY-MM-DD")}</td>
                                            <td>{moment(item.bookingData.checkOut).format("YYYY-MM-DD")}</td>
                                        </Fragment> : payoutType === CATEGORY_TYPE.CAR ?
                                            <Fragment>
                                                <td>{moment(item.bookingData.checkIn).format("YYYY-MM-DD")}</td>
                                                <td>{moment(item.bookingData.checkOut).format("YYYY-MM-DD")}</td>
                                            </Fragment> : <td>{moment(item.bookingData.date).format("YYYY-MM-DD")}</td>}

                                    <td>{PAYMENT_TYPE[item.bookingData.modeOfPayment]}</td>
                                    <td>{item.settlementCurrency} {item.amountPerDay}</td>
                                    <td>{item.settlementCurrency} {item.serviceCharge && item.serviceCharge.toFixed(2)}</td>
                                    <td>{item.settlementCurrency} {item.settlementAmount}</td>
                                </tr>
                            ))}
                            {
                                !payoutLoader && payouts.length !== 0 &&
                                <tr className={styles.totalAmountTab}>
                                    <td></td>
                                    <td className={styles.totalAmount}>Total</td>
                                    <td colSpan={4}></td>
                                    <td className={styles.totalAmount}>AED {amount.totalVendorAmount}</td>
                                    <td className={styles.totalAmount}>AED {amount.rentVIPAmount && amount.rentVIPAmount.toFixed(2)}</td>
                                    <td className={styles.totalAmount}>AED {amount.overallAmount && amount.overallAmount.toFixed(2)}</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
                <Pagination count={payoutsCount} setPagination={setPagination} />
            </div>

            <div
                className={`modal ${isGenerateInvoice ? "fade show d-block" : "d-none"}`}
                tabIndex="-1"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Generate Invoice</h5>
                            <button
                                type="button"
                                className={`${styles.closeButton} btn-close`}
                                onClick={() => setGenerateInvoice(false)}
                            ></button>
                        </div>

                        <div className="modal-body">
                            <label>Select Card</label>
                            <select
                                name="bankAccountId"
                                onChange={(event) => bankAccountFormik.setFieldValue('bankAccountId', event.target.value)}
                                className="w-100 form-control"
                                value={bankAccountFormik.values.bankAccountId}
                            >
                                <option value="">Click to select</option>
                                {payoutCards.map(item => (
                                    <option key={item._id} value={item._id}>{item.accountNumber}</option>
                                ))}
                            </select>
                            <div className="d-flex justify-content-end mt-4">
                                <Button title="Submit" handleClick={bankAccountFormik.handleSubmit} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    listPayoutCards: (loader, query) => dispatch(VendorPayoutAction.listPayoutCards(loader, query)),
    list: (params, loader, selectedBookings) => dispatch(VendorPayoutAction.payoutsList(params, loader, selectedBookings)),
    generateInvoice: (loader, payoutType, limit, bankAccountId, selectedBookings) => dispatch(VendorPayoutAction.generateInvoice(loader, payoutType, limit, bankAccountId, selectedBookings)),
});


export default connect(null, mapDispatchToProps)(Payouts);