import React, { useRef } from "react";
import { Modal } from "antd";
import Slider from "react-slick";
import styles from "./style.module.css";

const GalleryModal = ({
  visible,
  onClose,
  images,
  initialIndex = 0,
  imageCount,
}) => {
  const modalBigSliderRef = useRef(null);
  const modalSmallSliderRef = useRef(null);
  const [activeIndex, setActiveIndex] = React.useState(initialIndex);

  React.useEffect(() => {
    setActiveIndex(initialIndex);
  }, [initialIndex]);

  const modalBigSliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    asNavFor: modalSmallSliderRef.current,
    ref: modalBigSliderRef,
    initialSlide: activeIndex,
    afterChange: (index) => {
      setActiveIndex(index);
      modalSmallSliderRef.current?.slickGoTo(index);
    }
  };

  const modalSmallSliderSettings = {
    dots: false,
    infinite: images.length > 1,
    speed: 500,
    slidesToShow: 11,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: images.length > 1,
    focusOnSelect: true,
    asNavFor: modalBigSliderRef.current,
    ref: modalSmallSliderRef,
    initialSlide: activeIndex,
    centerMode: false,
    draggable: images.length > 1,
    responsive: [
      { breakpoint: 1200, settings: { slidesToShow: 7, slidesToScroll: 1 } },
      { breakpoint: 992, settings: { slidesToShow: 5, slidesToScroll: 1 } },
      { breakpoint: 768, settings: { slidesToShow: 4, slidesToScroll: 1 } },
      { breakpoint: 576, settings: { slidesToShow: 3, slidesToScroll: 1 } },
      { breakpoint: 374, settings: { slidesToShow: 2, slidesToScroll: 1 } },
    ]
  };

  if (!images || images.length === 0) {
    return null;
  }

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      width="100%"
      style={{
        top: 0,
        padding: 0,
        maxWidth: '100vw',
        margin: 0,
        height: '100vh',
        backgroundColor: "black",
        overflow: "hidden",
      }}
      title={
        <div className="image-counter" style={{ color: 'white' }}>
          {activeIndex + 1} / {imageCount || images.length}
        </div>
      }
      centered
      className={styles.galleryModal}
    >
      <div className={styles.galleryMainImage}>
        <Slider {...modalBigSliderSettings}>
          {images.map((img, index) => (
            <div key={index} className="w-100">
              <img
                className="img-fluid mx-auto"
                src={img}
                alt={`Gallery Image ${index + 1}`}
              />
            </div>
          ))}
        </Slider>
      </div>
      <div className={styles.thumbnailContainer}>
        <Slider {...modalSmallSliderSettings}>
          {images.map((img, index) => (
            <div key={index} style={{ padding: "0 5px" }}>
              <div className="thumbnail">
                <img
                  className={`img-fluid ${index === activeIndex ? styles.thumbnailActive : styles.thumbnailInactive}`}
                  src={img}
                  alt={`Thumbnail ${index + 1}`}
                  onClick={() => modalBigSliderRef.current?.slickGoTo(index)}
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </Modal>
  );
};

export default GalleryModal; 