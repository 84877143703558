import { API_ENDPOINTS } from "../../../../constants";
import { Axios } from "../../../../lib";

export const createEmployee = async (params) => {
  try {
    const response = await Axios.post(API_ENDPOINTS.ADD_TEAM, params);
    return response;
  } catch (error) {
    return error;
  }
};

export const getEmployeeList = async (filters) => {
  try {
    // Create a copy of filters to modify
    const queryParams = { ...filters };
    
    // Remove empty filters
    Object.keys(queryParams).forEach(key => {
      if (queryParams[key] === "" || queryParams[key] === null || queryParams[key] === undefined) {
        delete queryParams[key];
      }
    });

    // Format date if present
    if (queryParams.date) {
      // Convert date to ISO format and set time to start of day
      const startDate = new Date(queryParams.date);
      startDate.setHours(0, 0, 0, 0);
      
      // Set end date to end of the selected day
      const endDate = new Date(queryParams.date);
      endDate.setHours(23, 59, 59, 999);
      
      // Add date range to query params
      queryParams.startDate = startDate.toISOString();
      queryParams.endDate = endDate.toISOString();
      
      // Remove the original date parameter
      delete queryParams.date;
    }

    // Ensure limit and offset are always included
    queryParams.limit = queryParams.limit || 10;
    queryParams.offset = queryParams.offset || 0;

    // Convert query params to URL string
    const queryString = Object.keys(queryParams)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
      .join('&');

    const url = `${API_ENDPOINTS.GET_EMPLOYEE_LIST}${queryString ? `?${queryString}` : ''}`;
    
    console.log("API Request URL:", url);
    const response = await Axios.get(url);
    console.log("API Response:", response);
    return response;
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
};

export const updateEmployee = async (params) =>{
  try{
    console.log("updateEmployee called with params:", params);
    const { _id, ...employeeData } = params;
    console.log("Employee ID:", _id);
    console.log("Employee data:", employeeData);
    
    // Try a different approach - using URL with ID
    const url = `${API_ENDPOINTS.UPDATE_EMPLOYEE_LIST}/${_id}`;
    console.log("Making request to URL:", url);
    
    const response = await Axios.patch(url, employeeData);
    console.log("Update API response:", response);
    return response;
  }catch(error){
    console.error("Error in updateEmployee:", error);
    return error;
  }
}

export const deleteEmployee = async (employeeId) => {
  try {
    const response = await Axios.patch(`${API_ENDPOINTS.DELETE_EMPLOYEE}/${employeeId}`);
    console.log("Employee deletion request sent successfully");
    return response;
  } catch (error) {
    console.error("Error deleting employee:", error);
    throw error;
  }
}

export const handleStatusToggle = async (employeeId) =>{
  try{
   const response = await Axios.patch(`${API_ENDPOINTS.UPDATE_STATUS}/${employeeId}`);
   console.log("Status updated successfully")
   return response
  }catch(error){
   console.error("Error in update status",error);
   throw error;
  }
}



