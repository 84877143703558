import { APP_ROUTES } from "../../../routes/appRoutes";
import { storage } from "../../../utils";

export const SideBar = [
  {
    path: APP_ROUTES.DASHBOARD,
    name: "Dashboard",
    class: "fas fa-desktop",
    to: APP_ROUTES.DASHBOARD,
    vendor: true,
    admin: true
  },
  {
    path: APP_ROUTES.PROPERTY_CALENDAR,
    name: "Calendar",
    class: "fas fa-calendar-alt",
    to: APP_ROUTES.PROPERTY_CALENDAR,
    vendor: true,
    admin: false
  },
  {
    path: APP_ROUTES.USERS_LIST,
    name: "Users",
    class: "far fa-user",
    to: APP_ROUTES.USERS_LIST,
    vendor: false,
    admin: true
  },
  {
    path: APP_ROUTES.VENDOR_LIST,
    name: "Vendors",
    class: "far fa-list-alt",
    to: APP_ROUTES.VENDOR_LIST,
    vendor: false,
    admin: true
  },
  {
    path: APP_ROUTES.PROPERTIES_LIST,
    name: "Properties",
    class: "far fa-building",
    to: APP_ROUTES.PROPERTIES_LIST,
    activePage: [APP_ROUTES.PROPERTIES_LIST, APP_ROUTES.ADD_PROPERTY],
    vendor: true,
    admin: true
  },
  {
    path: APP_ROUTES.CARS_LIST,
    name: "Cars",
    class: "fas fa-car",
    to: APP_ROUTES.CARS_LIST,
    activePage: [APP_ROUTES.CARS_LIST, APP_ROUTES.ADD_CAR],
    vendor: true,
    admin: true
  },
  {
    path: APP_ROUTES.TOURS_LIST,
    name: "Tours",
    class: "fa fa-fighter-jet",
    to: APP_ROUTES.TOURS_LIST,
    activePage: [APP_ROUTES.TOURS_LIST, APP_ROUTES.ADD_TOUR],
    vendor: true,
    admin: true
  },
  {
    path: APP_ROUTES.PROPERTY_REQUESTS,
    name: "Property Requests",
    class: "far fa-building",
    to: APP_ROUTES.PROPERTY_REQUESTS,
    vendor: true,
    admin: true
  },
  {
    path: APP_ROUTES.VIEW_PAYOUTS,
    name: "Payouts",
    class: "fa fa-credit-card",
    to: APP_ROUTES.VIEW_PAYOUTS,
    activePage: [
      APP_ROUTES.VIEW_PAYOUTS,
      APP_ROUTES.VIEW_PAYOUTS_SETTINGS,
      APP_ROUTES.ADD_PAYOUT_CARD
    ],
    vendor: true,
    admin: false
  },
  {
    path: APP_ROUTES.ADD_TEAM,
    name: "Add my team",
    class: "fas fa-question-circle",
    to: APP_ROUTES.ADD_TEAM,
    activePage: [APP_ROUTES.ADD_TEAM],
    vendor: true,
    admin: false
  },
  {
    path: APP_ROUTES.VIEW_ADMIN_PAYOUTS,
    name: "Payouts",
    class: "fa fa-credit-card",
    to: APP_ROUTES.VIEW_ADMIN_PAYOUTS,
    activePage: [APP_ROUTES.VIEW_ADMIN_PAYOUTS],
    vendor: false,
    admin: true
  },
  {
    path: APP_ROUTES.VIEW_INVOICES,
    name: "Invoices",
    class: "fas fa-money-bill",
    to: APP_ROUTES.VIEW_INVOICES,
    vendor: false,
    admin: true
  },
  {
    path: APP_ROUTES.PROMOTIONS,
    name: "Promotions",
    class: "fa fa-bullhorn",
    to: APP_ROUTES.PROMOTIONS,
    vendor: false,
    admin: true
  },
  {
    path: APP_ROUTES.VOUCHERS,
    name: "Vouchers",
    class: "fa fa-clipboard",
    to: APP_ROUTES.VOUCHERS,
    vendor: false,
    admin: true
  },
  {
    path: APP_ROUTES.CATEGORIES_LIST,
    name: "Categories",
    class: "fas fa-list",
    to: APP_ROUTES.CATEGORIES_LIST,
    vendor: false,
    admin: true
  },
  {
    path: APP_ROUTES.CITIES_LIST,
    name: "Cities",
    class: "far fa-building",
    to: APP_ROUTES.CITIES_LIST,
    vendor: false,
    admin: true
  },
  {
    path: APP_ROUTES.CHATS_LIST,
    name: "Chat",
    class: "far fa-comment",
    to: APP_ROUTES.CHATS_LIST,
    vendor: true,
    admin: true
  },
  {
    path: APP_ROUTES.IMAGE_CATEGORY,
    name: "Image Category",
    class: "fas fa-list",
    to: APP_ROUTES.IMAGE_CATEGORY,
    vendor: false,
    admin: true
  },
  {
    path: APP_ROUTES.NOTIFICATIONS_LIST,
    name: "Notifications",
    class: "fas fa-bell",
    to: APP_ROUTES.NOTIFICATIONS_LIST,
    vendor: true,
    admin: true
  },
  {
    path: APP_ROUTES.CMS,
    vendor: false,
    admin: true
  },
  {
    path: APP_ROUTES.FAQS,
    name: "FAQ",
    class: "fas fa-question-circle",
    to: APP_ROUTES.FAQS,
    vendor: false,
    admin: true
  },

  {
    path: "/logout",
    name: "Logout",
    class: "fas fa-sign-out-alt",
    to: "/login",
    vendor: true,
    admin: true,
    function: () => storage.clearToken()
  }
];
