import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { createEmployeeValidation } from "../Validations";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { createEmployee, updateEmployee } from "../Api";
import { toast } from "react-toastify";
import { Toaster } from "../../../../components";
import { TOAST_TYPE } from "../../../../constants";

export default function CreateEmployeeModal(props) {
  const { styles, setCreateEmployeeModal, employeesData } = props;
  const isEditMode = !!employeesData;
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(() => {
      setCreateEmployeeModal(false);
    }, 300); // Match the transition duration
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      department: "",
      DateOfJoining: "",
    },
    validationSchema: createEmployeeValidation,
    onSubmit: async (values, { setFieldError }) => {
      try {
        let response;
        
        // Format the date properly if it exists
        const formattedValues = { ...values };
        if (formattedValues.DateOfJoining) {
          // Convert Date object to ISO string (YYYY-MM-DD format)
          formattedValues.DateOfJoining = formattedValues.DateOfJoining instanceof Date 
            ? formattedValues.DateOfJoining.toISOString().split('T')[0]
            : formattedValues.DateOfJoining;
        }
        
        if (isEditMode) {
          // Update existing employee
          console.log("Updating employee with ID:", employeesData._id);
          console.log("Update data:", formattedValues);
          response = await updateEmployee({
            ...formattedValues,
            _id: employeesData._id
          });
          console.log("Update response:", response);
        } else {
          // Create new employee
          console.log("Creating new employee");
          response = await createEmployee({
            ...formattedValues,
          });
          console.log("Create response:", response);
        }
        
        // Check for response errors
        console.log("Full response object:", response);
        
        if (response?.error || response?.response?.data?.error) {
          const errorMessage = response?.error || response?.response?.data?.error || "Something went wrong";
          console.error("Error detected:", errorMessage);
          
          // Handle specific error cases
          if (errorMessage.includes("email") || errorMessage.includes("Email")) {
            // If it's an email-related error, set it as a field error
            setFieldError("email", errorMessage);
          }
          // Show toast notification for the error
          Toaster(TOAST_TYPE.ERROR, errorMessage);
          return; // Don't close modal on error
        }
        
        // Success case
        Toaster(
          TOAST_TYPE.SUCCESS, 
          isEditMode ? "Employee updated successfully!" : "Employee added successfully!"
        );
        handleClose();
      } catch (error) {
        console.error("Error creating/updating employee:", error);
        Toaster(TOAST_TYPE.ERROR, "Something went wrong. Please try again.");
      }
    },
  });

  // Initialize form with employee data when in edit mode
  useEffect(() => {
    if (isEditMode && employeesData) {
      // Parse date string to Date object if it exists
      const dateOfJoining = employeesData.DateOfJoining 
        ? new Date(employeesData.DateOfJoining) 
        : "";
      
      formik.setValues({
        name: employeesData.name || "",
        email: employeesData.email || "",
        department: employeesData.department || "",
        DateOfJoining: dateOfJoining,
      });
    }
  }, [employeesData]);

  return (
    <div
      className={`${styles.modal} ${isVisible ? styles.show : ''}`}
      id="payloadmodal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className={styles["modal-dialog"]}>
        <div className={styles["modal-content"]}>
          <div className={styles["modal-header"]}>
            <button
              type="button"
              className={styles["btn-close"]}
              aria-label="Close"
              onClick={handleClose}
            >×</button>
          </div>
          <div className={styles["modal-body"]}>
            <div className={styles.employData}>
              <h4>{isEditMode ? "Edit Employee" : "Add New Employee"}</h4>
              <form onSubmit={formik.handleSubmit}>
                <div className={styles.formGroup}>
                  <label>Employee Name</label>
                  <input
                    type="text"
                    placeholder="Enter Name"
                    name="name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className={styles.error}>{formik.errors.name}</div>
                  )}
                </div>

                <div className={styles.formGroup}>
                  <label>Employee Email</label>
                  <input
                    type="email"
                    placeholder="Enter Email"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className={styles.error}>{formik.errors.email}</div>
                  )}
                </div>

                <div className={styles.formGroup}>
                  <label>Department</label>
                  <select
                    name="department"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.department}
                  >
                    <option value="">Select Department</option>
                    <option value="Accounts">Accounts</option>
                    <option value="PropertyManager">PropertyManager</option>
                    <option value="Support">Support</option>
                    <option value="BookingManager">BookingManager</option>
                  </select>
                  {formik.touched.department && formik.errors.department && (
                    <div className={styles.error}>
                      {formik.errors.department}
                    </div>
                  )}
                </div>

                <div className={styles.formGroup}>
                  <label>Date of Joining</label>
                  <DatePicker
                    selected={formik.values.DateOfJoining}
                    name="DateOfJoining"
                    onChange={(date) =>
                      formik.setFieldValue("DateOfJoining", date)
                    }
                    onBlur={formik.handleBlur}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Select a date"
                  />
                  {formik.touched.DateOfJoining &&
                    formik.errors.DateOfJoining && (
                      <div className={styles.error}>
                        {formik.errors.DateOfJoining}
                      </div>
                    )}
                </div>

                <div className={styles.formGroup}>
                  <button type="submit">{isEditMode ? "Update" : "Add"}</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
