import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { cmsData } from "../../../store/slices/authSlice";
import { SideBar } from "./sideBarRoutes";
import sidebar from "./style.module.css"
import { CMS_TYPE, USER_TYPE } from "../../../constants";
import { APP_ROUTES } from "../../../routes";
import { Logo } from "../../../assets/images";

const Sidebar = ({ open, pathname }) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const { user } = useSelector(state => state.common);

    const [bookingRequest, setBookingRequestMenu] = useState(false);
    const [calendarRequest, setCalendarRequestMenu] = useState(false);
    const [submenu, setSubmenu] = useState(false);

    const sunMenu = () => {
        setSubmenu(!submenu);
    };

    const handleMobileSidebarChange = () => {
        if (window.innerWidth < 767) {
            open();
        }
    };

    const cmsType = (type) => dispatch(cmsData(type));

    return (
        <div className={sidebar["side-bar"]}>
            <div className={sidebar["logo"]}>
                <Link to={APP_ROUTES.DASHBOARD}>
                    <img src={Logo} alt="logo" />
                </Link>
                <span className={sidebar["side-br-mobile"]}>
                    <i className="fas fa-bars" onClick={open}></i>
                </span>
            </div>

            <ul>
                {SideBar.map((val, index) => {
                    const list =
                        val.path === APP_ROUTES.CMS ? (
                            <li key={index} className={`${sidebar["down-arow"]} cursor-pointer`} onClick={sunMenu}>
                                <i className="far fa-address-card"></i>Content Management
                                {submenu && (
                                    <ul className={sidebar["submenu"]}>
                                        <li onClick={() => cmsType(CMS_TYPE.ABOUT_US)}>
                                            <Link to={{ pathname: APP_ROUTES.CMS, search: "?type=ABOUT_US" }}>About us</Link>
                                        </li>
                                        <li onClick={() => cmsType(CMS_TYPE.TERM_AND_CONDITION)}>
                                            <Link to={APP_ROUTES.CMS}>Terms & Condition</Link>
                                        </li>
                                        <li onClick={() => cmsType(CMS_TYPE.PRIVACY_POLICY)}>
                                            <Link to={APP_ROUTES.CMS}>Privacy Policy</Link>
                                        </li>
                                    </ul>
                                )}
                            </li>
                        ) : [APP_ROUTES.PROPERTY_REQUESTS].includes(val.path) ?
                            <li key={index} className={`${sidebar["down-arow"]} cursor-pointer`} onClick={() => setBookingRequestMenu(!bookingRequest)}>
                                <i className="far fa-address-card"></i>Booking Requests
                                {(bookingRequest || (pathname.startsWith(APP_ROUTES.PROPERTY_REQUESTS) || pathname.startsWith(APP_ROUTES.CAR_REQUESTS))) && (
                                    <ul className={`${sidebar["submenu"]} mt-2`} >
                                        <li onClick={handleMobileSidebarChange}>
                                            <Link className={location.pathname.startsWith(APP_ROUTES.PROPERTY_REQUESTS) ? sidebar.active : null} to={APP_ROUTES.PROPERTY_REQUESTS}>
                                                <i className="far fa-building me-4" />
                                                Property
                                            </Link>
                                        </li>
                                        <li onClick={handleMobileSidebarChange}>
                                            <Link className={location.pathname.startsWith(APP_ROUTES.CAR_REQUESTS) ? sidebar.active : null} to={APP_ROUTES.CAR_BOOKINGS_LIST}>
                                                <i className="fas fa-car me-4" />
                                                Car
                                            </Link>
                                        </li>
                                        {/* <li onClick={handleMobileSidebarChange}>
                                            <Link className={location.pathname === APP_ROUTES.TOUR_BOOKINGS ? 'active' : null} to={APP_ROUTES.TOUR_BOOKINGS}>
                                                <i className="fa fa-fighter-jet me-3" />
                                                Tour
                                            </Link>
                                        </li> */}
                                    </ul>
                                )}
                            </li> : [APP_ROUTES.PROPERTY_CALENDAR].includes(val.path) ?
                                <li
                                    key={index}
                                    className={`${sidebar["down-arow"]} cursor-pointer ${pathname === APP_ROUTES.PROPERTY_CALENDAR ? sidebar.active : sidebar.inactive}`}
                                    onClick={() => setCalendarRequestMenu(!calendarRequest)}
                                >
                                    <i className="far fa-address-card"></i>Calendar
                                    {(calendarRequest || (pathname === APP_ROUTES.PROPERTY_CALENDAR || pathname === APP_ROUTES.TOUR_CALENDAR)) && (
                                        <ul className={`${sidebar["submenu"]} mt-2`}>
                                            <li onClick={handleMobileSidebarChange}>
                                                <Link className={pathname === APP_ROUTES.PROPERTY_CALENDAR ? sidebar.active : null} to={APP_ROUTES.PROPERTY_CALENDAR}>
                                                    <i className="far fa-building me-4" />
                                                    Property
                                                </Link>
                                            </li>
                                            <li onClick={handleMobileSidebarChange}>
                                                <Link className={pathname === APP_ROUTES.TOUR_CALENDAR ? sidebar.active : null} to={APP_ROUTES.TOUR_CALENDAR}>
                                                    <i className="fa fa-fighter-jet me-3" />
                                                    Tour
                                                </Link>
                                            </li>
                                        </ul>
                                    )}
                                </li> : (
                                    <li key={index} className={(pathname === val.path) || (val.activePage && val.activePage.includes(pathname)) ? sidebar.active : sidebar.inactive}>
                                        <Link onClick={() => {
                                            if (val.function) {
                                                val.function();
                                            }
                                            handleMobileSidebarChange();
                                        }} to={val.to}>
                                            <i className={val.class}></i>
                                            {val.name}
                                        </Link>
                                    </li>
                                );

                    if (user.userType === USER_TYPE.VENDOR && val.vendor) return list;
                    if (user.userType === USER_TYPE.ADMIN && val.admin) return list;
                })}
            </ul>
        </div>
    );
}

export default Sidebar;
