import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import { connect, useSelector } from "react-redux";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Link, useNavigate } from "react-router-dom";

import { BookingRequest, Pagination } from "../../../../components";
import { VendorBookingRequestAction } from "../../../../store/vendor/thunk";
import { BOOKING_REQUESTS_TYPE } from "../../../../constants/promotions";
import styles from "../style.module.css";
import commonStyles from "../../../Common/styles/common.module.css";
import { CommonAction } from "../../../../store/common/thunk";
import { BOOKING_PAYMENT_STATUS, CATEGORY_TYPE, MESSAGES, USER_TYPE } from "../../../../constants";
import { NoBookingIcon, PhotoIcon } from "../../../../assets/images";

const PropertyBookingRequests = ({ loader, getPropertyBookings, acceptPayment, getProperties }) => {
    const navigate = useNavigate();

    const { bookingRequests: { propertyBookings, propertyBookingCount, requestLoader } } = useSelector((state) => state.vendor);
    const { propertiesList, user } = useSelector((state) => state.common);

    const [pagination, setPagination] = useState({ offset: 0, limit: 10 });
    const [tab, setTab] = useState("");
    const [propertyFilter, setPropertyFilter] = useState("");
    const [filterDate, setDates] = useState({ startDate: null, endDate: null });

    const getBookingRequests = async (offset = pagination.offset, status = tab, propertyId = propertyFilter) => {
        let params = {
            limit: pagination.limit,
            offset,
        };

        if (status.length) {
            params.status = status;
        }

        if (propertyId.length) {
            params.propertyId = propertyId;
        }

        if (filterDate.startDate && filterDate.endDate) {
            params.fromDate = filterDate.startDate;
            params.toDate = filterDate.endDate;
            delete params.status;
        }

        getPropertyBookings(params, loader);
    };

    const switchHandler = async (bookingId) => {
        const payload = { type: CATEGORY_TYPE.PROPERTY, bookingId };

        const params = {
            bookingType: BOOKING_REQUESTS_TYPE.PROPERTY_BOOKING,
            limit: pagination.limit,
            offset: pagination.offset,
        };

        acceptPayment(payload, loader, params);
    };

    const handleActiveTab = (tab) => {
        setTab(tab);

        getBookingRequests(0, tab);
    };

    const handleDateChange = (dates) => {
        const [startDate, endDate] = dates;

        if ((startDate && endDate) || (!startDate && !endDate)) {
            getBookingRequests();
        }
        setDates({ startDate, endDate });
    };

    const handlePropertyChange = (event) => {
        const { target: { value } } = event;

        setPropertyFilter(value);
        getBookingRequests(0, "", value);
    };

    useEffect(() => {
        getBookingRequests();
    }, [pagination]);

    useEffect(() => {
        if (user && user.userType === USER_TYPE.VENDOR) {
            const params = {
                offset: 0,
                limit: 100,
            };

            getProperties(params, loader);
        }

    }, [user])

    return (
        <>
        <BookingRequest/>
        <div className={commonStyles["vendor-listing"]}>
          <div className="d-flex justify-content-between align-items-center mb-4 filter-pro">
            {/* <h3 className="mb-0">Property Reservations</h3> */}
            <div className="d-flex justify-content-between align-items-center">
              <ul className="paginaion">
                <li>
                  <Link to="/">Dashboard</Link>
                </li>
                <li className="active">
                  <Link to="/propertyBookings">Property Bookings</Link>
                </li>
              </ul>
            </div>
            <div className="d-flex filter-pro">
              {!filterDate.endDate ? (
                <select
                  onChange={(event) => handleActiveTab(event.target.value)}
                >
                  <option value="">Filter by Status</option>
                  <option value="UPCOMING">Upcoming</option>
                  <option value="ACTIVE">Active</option>
                  <option value="PAST">Past</option>
                </select>
              ) : null}
              <DatePicker
                monthsShown={2}
                selectsRange
                startDate={filterDate.startDate}
                selected={filterDate.startDate}
                endDate={filterDate.endDate}
                onChange={handleDateChange}
                className={styles.filterDate}
                dateFormat="dd MMM yyyy"
                placeholderText="Filter by Date"
                isClearable
              />
              {user.userType === USER_TYPE.VENDOR ? (
                <select
                  value={propertyFilter}
                  onChange={handlePropertyChange}
                  className={styles.propertyList}
                >
                  <option value="">Select Property</option>
                  {propertiesList.map((item) => (
                    <option key={item._id} value={item._id}>
                      {item.name} ({item.unitNumber})
                    </option>
                  ))}
                </select>
              ) : null}
            </div>
          </div>

          {!requestLoader && propertyBookings.length === 0 ? (
            <div className={styles.noBookingContainer}>
              <img width={300} src={NoBookingIcon} alt="no-booking" />
              <p>No Booking Found</p>
            </div>
          ) : null}
          {requestLoader ? (
            <div className="d-flex justify-content-center align-items-center flex-column loading-text">
              <h5>{MESSAGES.LOADING}</h5>
            </div>
          ) : null}

          {!requestLoader &&
            propertyBookings.map((request) => (
              <div
                key={request._id}
                className={styles.bookingCard}
                onClick={() => navigate(`/propertyBookings/${request._id}`)}
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className={styles["gray-first"]}>
                      <div className="d-flex align-items-center">
                        <img
                          className={styles.userImage}
                          src={request.userImage}
                          alt="user"
                        />
                        <p className={styles.userName}>
                          {request.userFirstName} {request.userLastName}
                        </p>
                      </div>

                      <p className={styles.email}>
                        <i className="far fa-envelope"></i>
                        {request.userEmail}
                      </p>
                      <p className={styles.mobileNo}>
                        <i className="fas fa-phone-square-alt"></i>
                        {request.phoneNumber}
                        {request.promotionData ? (
                          <span className={styles.promotion}>
                            {request.promotionData.promotionName}
                          </span>
                        ) : null}
                      </p>
                    </div>
                  </div>

                  <div className="col-md-4 d-flex flex-column justify-content-center align-items-center">
                    <div className={styles["gray-clr"]}>
                      <p className={styles.property}>
                        {request?.propertyName?.length > 30
                          ? `${request?.propertyName?.slice(0, 30)}...`
                          : request?.propertyName}
                      </p>
                      <p className={styles.checkInDates}>
                        {moment(request.checkIn).format("MMM DD, YYYY")} -{" "}
                        {moment(request.checkOut).format("MMM DD, YYYY")}
                      </p>
                      <p className={styles.members}>
                        {request.totalDays} nights - {request.memberString}
                      </p>
                      {request.voucherData && request.voucherData.voucherId ? (
                        <p className={styles.vouchers}>
                          Voucher Used ({request.voucherData.voucherName})
                        </p>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-md-2 d-flex flex-column justify-content-center">
                    {request.paymentButton ? (
                      <div className="d-flex align-items-center justify-content-end">
                        <p className={styles.paymentRequest}>Payment Request</p>
                        <Switch
                          onColor="#e89532"
                          onChange={() =>
                            switchHandler(request._id, !request.hasDonePayment)
                          }
                          checked={request.hasDonePayment}
                          disabled={request.hasDonePayment}
                        />
                      </div>
                    ) : null}
                    <div className={styles["gray-first"]}>
                      <p className={styles.price}>
                        {request.price
                          ? `${request.currencySymbol} ${request.price.toFixed(
                              2
                            )}`
                          : "--"}{" "}
                      </p>
                      <p className={styles.createdAt}>
                        {moment(request.createdAt)
                          .tz(request.timezone || "Asia/Kolkata")
                          .format("MMM DD, YYYY")}
                      </p>
                      <p
                        className={
                          request.paymentStatus === 2 ||
                          request.paymentStatus === 5
                            ? styles.paid
                            : styles.pending
                        }
                      >
                        {BOOKING_PAYMENT_STATUS[request.paymentStatus]}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          <Pagination
            count={propertyBookingCount}
            setPagination={setPagination}
          />
        </div>
      </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    getPropertyBookings: (query, loader) => dispatch(VendorBookingRequestAction.propertyBookingRequests(query, loader)),
    acceptPayment: (payload, loader, params) => dispatch(VendorBookingRequestAction.acceptBooking(payload, loader, params)),
    getProperties: (query, loader) => dispatch(CommonAction.propertyList(query, loader)),
});

export default connect(null, mapDispatchToProps)(PropertyBookingRequests);
