import React, { useEffect, useState } from "react";

import Pagination from "../Pagination";
import { Axios } from "../../lib";
import commonStyles from "../../pages/Common/styles/common.module.css";
import tableStyles from "../../components/Table/style.module.css";

function Dailyrevenue({ loader }) {
    const [cities, setCities] = useState([]);
    const [revenuedata, setRevenue] = useState("PROPERTY");
    const [pagination, setPagination] = useState({
        offset: 0,
        limit: 10,
    });

    const getCities = async () => {
        try {
            const { status, data } = await Axios.get(
                "v1/admin/revenue?dailyRevenue=true",

                {
                    limit: pagination.limit,
                    offset: pagination.offset,
                },
                loader
            );

            if (status)
                setCities({
                    count: data.count,
                    cities: data,
                });
        } catch (error) { }
    };

    useEffect(() => {
        getCities();
    }, [pagination]);

    const spanStyle = {
        border: "1px solid #e89532",
        color: "#e89532",
        cursor: "pointer",
        fontSize: "18px",
        padding: "10px 21px",
        borderRadius: "5px",
        backgroundColor: "#fff",
        transition: "background-color 0.3s ease, color 0.3s ease",
    };

    const activeSpanStyle = {
        ...spanStyle,
        backgroundColor: "#e89532",
        color: "#fff",
        fontWeight: "700",
    };

    return (
        <div className={commonStyles["vendor-listing"]}>
            <ul>
                <li>
                    <span
                        style={revenuedata === "PROPERTY" ? activeSpanStyle : spanStyle}
                        onClick={() => setRevenue("PROPERTY")}
                    >
                        Properties
                    </span>
                </li>
                <li>
                    <span
                        style={revenuedata === "CAR" ? activeSpanStyle : spanStyle}
                        onClick={() => setRevenue("CAR")}
                    >
                        Car
                    </span>
                </li>
                <li>
                    <span
                        style={revenuedata === "TOUR" ? activeSpanStyle : spanStyle}
                        onClick={() => setRevenue("TOUR")}
                    >
                        Tours
                    </span>
                </li>
            </ul>
            {revenuedata === "PROPERTY" &&
                <>
                    <h3 className="mt-5">Daily Property Revenue</h3>
                    <div className={tableStyles["listing-table"]}>
                        <div className={tableStyles["listing-table-scrl"]}>
                            <table cellPadding={0} cellSpacing={0}>
                                <tr>
                                    <th>S No.</th>
                                    <th>Name</th>
                                    <th>Vendor Name</th>
                                    <th>Total Revenue</th>

                                </tr>

                                {cities.cities?.propertyRevenue.length === 0 ? <tr className="noresult"><td colSpan={4}>No result</td></tr> :
                                    cities.cities?.propertyRevenue && cities.cities.propertyRevenue.map((city, index) => (
                                        <tr key={city._id}>
                                            <td>{++index}</td>
                                            <td>
                                                {city.name}
                                            </td>
                                            <td>{city.vendorFirstName} {city.vendorLastName}</td>
                                            <td>{city.totalAmount}</td>


                                        </tr>
                                    ))}
                            </table>

                        </div>

                        <Pagination count={cities.count + 1} setPagination={setPagination} />
                    </div>

                </>
            }

            {revenuedata === "CAR" &&
                <>
                    <h3 className="mt-5"> Daily Car  Revenue</h3>
                    <div className={tableStyles["listing-table"]}>
                        <div className={tableStyles["listing-table-scrl"]}>
                            <table cellPadding={0} cellSpacing={0}>
                                <tr>
                                    <th>S No.</th>
                                    <th>Name</th>
                                    <th>Vendor Name</th>
                                    <th>Total Revenue</th>

                                </tr>

                                {cities.cities?.carRevenue.length === 0 ? <tr className="noresult"><td colSpan={4}>No result</td></tr> :
                                    cities.cities?.carRevenue && cities.cities.carRevenue.map((city, index) => (
                                        <tr key={city._id}>
                                            <td>{++index}</td>
                                            <td>
                                                {city.name}
                                            </td>
                                            <td>{city.vendorFirstName} {city.vendorLastName}</td>
                                            <td>{city.totalAmount}</td>


                                        </tr>
                                    ))}
                            </table>
                        </div>

                        <Pagination count={cities.count + 1} setPagination={setPagination} />
                    </div>

                </>
            }


            {revenuedata === "TOUR" &&
                <>
                    <h3 className="mt-5">Daily Tours  Revenue</h3>
                    <div className={tableStyles["listing-table"]}>
                        <div className={tableStyles["listing-table-scrl"]}>
                            <table cellPadding={0} cellSpacing={0}>
                                <tr>
                                    <th>S No.</th>
                                    <th>Name</th>
                                    <th>Vendor Name</th>
                                    <th>Total Revenue</th>

                                </tr>

                                {cities.cities?.tourRevenue.length === 0 ? <tr className="noresult"><td colSpan={4}>No result</td></tr> :
                                    cities.cities?.tourRevenue && cities.cities.tourRevenue.map((city, index) => (
                                        <tr key={city._id}>
                                            <td>{++index}</td>
                                            <td>
                                                {city.name}
                                            </td>
                                            <td>{city.vendorFirstName} {city.vendorLastName}</td>
                                            <td>{city.totalAmount}</td>


                                        </tr>
                                    ))}
                            </table>
                        </div>

                        <Pagination count={cities.count + 1} setPagination={setPagination} />
                    </div>

                </>
            }
        </div>
    );
}

export default Dailyrevenue

