import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import search from "../../../../src/assets/images/search.png";
import edit from "../../../../src/assets/images/edit.svg";
import deletee from "../../../../src/assets/images/delete.svg";
import info from "../../../../src/assets/images/info.svg";
import { getEmployeeList, deleteEmployee,handleStatusToggle } from "./Api";
import CreateEmployeModal from "./Components/CreateEmployeModal";
import { Toaster } from "../../../components";
import Swal from "sweetalert2";
import { TOAST_TYPE } from "../../../constants";

function Teams() {
  const [createEmployeeModal, setCreateEmployeeModal] = useState(false);
  const [editEmployeeData, setEditEmployeeData] = useState(null);
  const [employeesData, setEmployeesData] = useState({ list: [], count: 0 });
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    search: "",
    department: "",
    limit: 10,
    offset: 0
  });
  const [pagination, setPagination] = useState({
    totalCount: 0,
    currentPage: 1,
    totalPages: 1
  });

  // Function to handle modal close
  const handleModalClose = async () => {
    setCreateEmployeeModal(false);
    // If we were editing, refresh the employee list
    if (editEmployeeData) {
      await fetchEmployees();
    }
    setEditEmployeeData(null); // Reset edit data when modal is closed
  };

  const fetchEmployees = async () => {
    try {
      setLoading(true);
      console.log("Fetching employees with filters:", filters);
      const response = await getEmployeeList(filters);
      console.log("Raw API Response:", response);
      
      // Check if response exists and has data
      if (response && response.data) {
        const { employees, totalCount, currentPage, totalPages } = response.data;
        console.log("Parsed data:", { employees, totalCount, currentPage, totalPages });
        
        // Set employees data
        setEmployeesData({
          list: employees || [],
          count: totalCount || 0,
        });
        
        // Set pagination data
        setPagination({
          totalCount: totalCount || 0,
          currentPage: currentPage || 1,
          totalPages: totalPages || 1
        });
      } else {
        console.error("Invalid API response structure:", response);
        Toaster(TOAST_TYPE.ERROR, "Invalid response from server");
      }
    } catch (error) {
      console.error("Error fetching employees:", {
        message: error.message,
        response: error.response,
        status: error.response?.status,
        data: error.response?.data
      });
      Toaster(TOAST_TYPE.ERROR, error.response?.data?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, [filters]);

  const handleSearch = (e) => {
    const value = e?.target?.value ?? "";
    setFilters(prev => ({ ...prev, search: value, offset: 0 }));
  };

  const handleDepartmentChange = (e) => {
    const value = e?.target?.value ?? "";
    setFilters(prev => ({ ...prev, department: value, offset: 0 }));
  };

  const handlePageChange = (newPage) => {
    setFilters(prev => ({ ...prev, offset: (newPage - 1) * prev.limit }));
  };

  const handleEdit = (employee) => {
    setEditEmployeeData(employee);
    setCreateEmployeeModal(true);
  };

  const deleteEmp = async (employeeId) => {
    console.log("Check employeeId:", employeeId);

    Swal.fire({
      title: "Are you sure?",
      text: "You want to remove this employee",
      confirmButtonText: "Yes",
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteEmployee(employeeId);
          Toaster("success", "Employee removed successfully.");
          setEmployeesData((prevData) => ({
            ...prevData,
            list: prevData.list.filter((item) => item._id !== employeeId),
            count: prevData.count - 1,
          }));
        } catch (error) {
          console.error("Error deleting employee:", error);
          Toaster("error", "Failed to remove employee.");
        }
      }
    });
  };
  const StatusToggle = async (_id) => {
    try {
      // Wait for the status update API to complete
      await handleStatusToggle(_id);
      // Fetch updated data after status change
      await fetchEmployees();
      Toaster("success", "Status updated successfully");
    } catch (error) {
      console.error("Error updating status:", error);
      Toaster("error", "Failed to update status");
    }
  };
  return (
    <>
      <div className={styles.AddteamOuter}>
        <div className={styles.topFilter}>
          <div className={styles.leftFilter}>
            <div className={styles.formGroup}>
              <span>
                <img src={search} alt="Search" />
              </span>
              <input
                type="text"
                placeholder="Search by name or email"
                value={filters.search}
                onChange={handleSearch}
              />
            </div>
            <div className={styles.formGroup}>
              <select
                value={filters.department}
                onChange={handleDepartmentChange}
              >
                <option value="">All Departments</option>
                <option value="Accounts">Accounts</option>
                <option value="PropertyManager">Property Manager</option>
                <option value="Support">Support</option>
                <option value="BookingManager">Booking Manager</option>
              </select>
            </div>
          </div>
          <div className={styles.rightFilter}>
            <button onClick={() => setCreateEmployeeModal(true)}>Add New</button>
          </div>
        </div>

        <div className={styles.teamTable}>
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Employee Name</th>
                <th>Email</th>
                <th>Department</th>
                <th>Date of Joining</th>
                <th>Relieving Date</th>
                <th>Assigned On</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="9" className={styles.loading}>
                    Loading...
                  </td>
                </tr>
              ) : employeesData.list.length === 0 ? (
                <tr>
                  <td colSpan="9" className={styles.emptyState}>
                    No employees found
                  </td>
                </tr>
              ) : (
                employeesData.list.map((item, index) => (
                  <tr key={item._id}>
                    <td>{(pagination.currentPage - 1) * filters.limit + index + 1}</td>
                    <td>{item?.name || "--"}</td>
                    <td>{item?.email || "--"}</td>
                    <td>{item?.department || "--"}</td>
                    <td>{item?.DateOfJoining?.split("T")[0] || "--"}</td>
                    <td>{item?.relievingDate || "--"}</td>
                    <td>{item?.createdAt?.split("T")[0] || "--"}</td>
                    <td>
                      <span
                        className={
                          item?.status === "Active" ? styles.active : styles.inactive
                        }
                        onClick={() => StatusToggle(item?._id)}
                        style={{ cursor: "pointer" }}
                      >
                        {item?.status || "Inactive"}
                      </span>
                    </td>
                    <td>
                      <span onClick={() => handleEdit(item)}>
                        <img src={edit} alt="Edit" />
                      </span>
                      <span onClick={() => deleteEmp(item?._id)}>
                        <img src={deletee} alt="Delete" />
                      </span>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        {/* Pagination Controls */}
        {pagination.totalPages > 1 && (
          <div className={styles.pagination}>
            <button
              onClick={() => handlePageChange(pagination.currentPage - 1)}
              disabled={pagination.currentPage === 1}
            >
              Previous
            </button>
            <div className={styles.pageInfo}>
              <span>Page {pagination.currentPage} of {pagination.totalPages}</span>
              <span className={styles.totalCount}>
                Total: {pagination.totalCount} employees
              </span>
            </div>
            <button
              onClick={() => handlePageChange(pagination.currentPage + 1)}
              disabled={pagination.currentPage === pagination.totalPages}
            >
              Next
            </button>
          </div>
        )}
      </div>

      {createEmployeeModal && (
        <CreateEmployeModal
          styles={styles}
          setCreateEmployeeModal={handleModalClose}
          employeesData={editEmployeeData}
        />
      )}
    </>
  );
}

export default Teams;
