import React, { Fragment, useEffect, useState, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { TopBar } from "./topBarRoutes";
import topbar from "../Topbar/style.module.css";
import { Axios } from "../../../lib";
import COMMON_ACTIONS from "../../../store/common/actions";
import { CommonAction } from "../../../store/common/thunk";
import { Bell } from "../../../assets/images";
import { storage } from "../../../utils";
import togglemob from "../../../assets/images/togglemob.png";
import load from "../../../assets/images/load.svg";
import notifyic from "../../../assets/images/notifyic.svg";
import home from "../../../assets/images/home.svg";
import cht from "../../../assets/images/chat.svg";
import menu from "../../../assets/images/menu.svg";
import booking from "../../../assets/images/booking.svg";
import mylisting from "../../../assets/images/mylisting.svg"
import { APP_ROUTES } from "../../../routes";

const Topbar = ({ open, pathname, notificationList, collapseSidebar }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [openNotification, setOpenNotification] = useState(false);
  const [lastElement, setLastElement] = useState(null);
  const notificationRef = useRef();

  const {
    user,
    notifications,
    unreadNotificationsCount,
    notificationPagination,
  } = useSelector((state) => state.common);

  const scrollObserver = useRef(
    new IntersectionObserver((entries) => {
      const first = entries[0];

      if (first.isIntersecting) {
        dispatch({ type: COMMON_ACTIONS.SET_NOTIFICATION_PAGINATION });
      }
    })
  );

  const collapse = () => {
    open();
  };

  const getNotifications = async () => {
    notificationList({ userType: user.userType, ...notificationPagination });
  };

  const checkForOutsideClick = (e) => {
    if (
      notificationRef.current &&
      !notificationRef.current.contains(e.target)
    ) {
      setOpenNotification(false);
    }
  };

  const notification = async () => {
    try {
      await Axios.put("v1/notification/count");

      // if (status) setCategory(data);
    } catch (error) {
      console.error("ERROR-->", error);
    }

    setOpenNotification(!openNotification);
  };

  useEffect(() => {
    collapse();

    document.addEventListener("click", checkForOutsideClick);

    return () => {
      document.removeEventListener("click", checkForOutsideClick);
    };
  }, []);

  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = scrollObserver.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement]);

  useEffect(() => {
    getNotifications();
  }, [notificationPagination]);

  return (
    <div id={topbar["top-bar-main"]} className="bg-white0">
      <div className="d-flex align-items-center justify-content-between">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div className={topbar["left-top-bar"]}>
            <div className={topbar["togglr-btn"]}>
              <span className="toggler" onClick={collapse}>
                <i className="fas fa-bars" />
                <img src={togglemob} alt="" />
              </span>
              <span class="mobileHide">
                <i
                  className="fas fa-chevron-left"
                  onClick={() => navigate(-1)}
                />
              </span>
            </div>

            <h4 className="TopTitle">{TopBar[pathname] ? TopBar[pathname] : "Rental VIP"}</h4>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div className={topbar["right-top-bar"]}>
            <div className={topbar["user-profile"]}>
              {user && user.firstName && user.lastName ? (
                <Fragment>
                  <span>{`${user.firstName} ${user.lastName}`}</span>
                  
                  {/* <img src={user.image} alt="" /> */}
                </Fragment>
              ) : null}
            </div>
            <div className={topbar["notfication"]}>
              <span onClick={notification}>
                <i className="fas fa-bell cursor-pointer" />
                {/* <img src={notifyic} alt="" /> */}
                {unreadNotificationsCount !== 0 ? (
                  <label>{unreadNotificationsCount}</label>
                ) : (
                  false
                )}
              </span>
              {openNotification && (
                <div className={topbar["notification"]} ref={notificationRef}>
                  <ul>
                    {notifications.map((notify) => (
                      <li
                        ref={setLastElement}
                        key={notify._id}
                        style={{
                          background: `${
                            notify && notify.isSeen === false
                              ? "#ececec"
                              : "#fff"
                          }`,
                        }}
                      >
                        <span>
                          <img src={Bell} alt="bell" />
                        </span>
                        <div>
                          <h5>{notify.title}</h5>
                          <p>{notify.body}</p>
                        </div>
                        {notify.isNew === true ? (
                          <label className="dot" />
                        ) : (
                          false
                        )}
                      </li>
                    ))}
                  </ul>
                  {/* <Link
                                            onClick={notification}
                                            className="view_all"
                                            to="/notifications"
                                        >
                                            View all
                                        </Link> */}
                </div>
              )}
            </div>
            <div className={topbar["logout"]}>
              <Link
                to="/login"
                onClick={() => {
                  storage.clearToken();
                  dispatch({
                    type: COMMON_ACTIONS.USER_DETAILS_SUCCESS,
                    user: {},
                  });
                }}
              >
                <i className="fas fa-sign-out-alt" /> 
                {/* <img src={load} alt="" /> */}
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className={topbar.bottoBar}>
        <ul>
          <li
            className={
              location?.pathname === APP_ROUTES.DASHBOARD
                ? topbar.active
                : ""
            }
          >
            <Link to={APP_ROUTES.DASHBOARD}>
              <img src={home} alt="" />
              <span>Home</span>
            </Link>
          </li>
          <li
            className={
              location?.pathname === APP_ROUTES.PROPERTY_REQUESTS ||
              location?.pathname === APP_ROUTES.CAR_BOOKINGS_LIST
                ? topbar.active
                : ""
            }
          >
            <Link to={APP_ROUTES.PROPERTY_REQUESTS}>
              <img src={booking} alt="" />
              <span>Bookings</span>
            </Link>
          </li>
          <li
            className={
              location?.pathname === APP_ROUTES.PROPERTIES_LIST ||
              location?.pathname === APP_ROUTES.CARS_LIST ||
              location?.pathname === APP_ROUTES.TOURS_LIST
                ? topbar.active
                : ""
            }
          >
            <Link to={APP_ROUTES.PROPERTIES_LIST}>
              <img src={mylisting} alt="" />
              <span>My Listing</span>
            </Link>
          </li>
          <li
            className={
              location?.pathname === APP_ROUTES.CHATS_LIST
                ? topbar.active
                : ""
            }
          >
            <Link to={APP_ROUTES.CHATS_LIST}>
              <img src={cht} alt="" />
              <span>Chat</span>
            </Link>
          </li>
          <li
            className={collapseSidebar ? topbar.active : ""}
            onClick={collapse}
          >
            <Link>
              <img src={menu} alt="" />
              <span>Menu</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  notificationList: (query, loader) =>
    dispatch(CommonAction.notificationsList(query, loader)),
});

export default connect(null, mapDispatchToProps)(Topbar);
