import React, { Fragment } from "react";
import { FormikProvider, useFormik } from "formik";
import MdEditor from "react-markdown-editor-lite";
import MarkdownIt from "markdown-it";
import { connect, useDispatch, useSelector } from "react-redux";
import Autocomplete from "react-google-autocomplete";

import { editBasicCarSchema, editCarLocationSchema, editCarFeaturesSchema } from "../../../../../schemas/editCarSchema";
import { EDIT_CAR_TYPE, FILE_UPLOAD_TYPE, IMAGE_FILE_TYPES, MAXIMUM_FILE_SIZE_LIMIT, MESSAGES, MINIMUM_FILE_UPLOAD_LIMIT, TOAST_TYPE, EDITOR_PLUGINS } from "../../../../../constants";
import { useEffect } from "react";
import { VendorCarAction } from "../../../../../store/vendor/thunk";
import { processImage, uploadImage } from "../../../../../utils/imageHelper";
import { Toaster } from "../../../../../components";
import DropImage from "../../../../../components/DropImage";
import styles from "./style.module.css";
import COMMON_ACTIONS from "../../../../../store/common/actions";

const EditCar = (props) => {
    const dispatch = useDispatch();
    const mdParser = new MarkdownIt({ html: true, linkify: true, typographer: true });

    const { images } = useSelector((state) => state.common);

    const editBasicCarFormik = useFormik({
        initialValues: { name: "", brand: "", model: "", price: "" },
        enableReinitialize: true,
        validationSchema: editBasicCarSchema,
        onSubmit: (values) => {
            props.update({ ...values, car_id: props.car._id }, props.loader, props.car.slug);

            props.closeEditCarModal();
        },
    });

    const editDescriptionFormik = useFormik({
        initialValues: { description: "" },
        enableReinitialize: true,
        onSubmit: (values) => {
            props.update({ ...values, car_id: props.car._id }, props.loader, props.car.slug);
            props.closeEditCarModal();
        },
    });

    const editFeaturesFormik = useFormik({
        initialValues: { mileage: "", transmission: "", seats: "", engine: "", bhp: "", bootSpace: "" },
        enableReinitialize: true,
        validationSchema: editCarFeaturesSchema,
        onSubmit: (values) => {
            const payload = {
                mileage: values.mileage,
                transmission: values.transmission,
                seats: values.seats,
                engine: values.engine,
                bhp: values.bhp,
                boot_space: values.bootSpace
            };
            props.update({ features: payload, car_id: props.car._id }, props.loader, props.car.slug);
            props.closeEditCarModal();
        },
    });

    const editCarLocationFormik = useFormik({
        initialValues: { latitude: 0, longitude: 0, location: "" },
        enableReinitialize: true,
        validationSchema: editCarLocationSchema,
        onSubmit: (values) => {
            props.update({ ...values, car_id: props.car._id }, props.loader, props.car.slug);
            props.closeEditCarModal();
        },
    });

    const handleKeyDown = (e) => {
        if (e.key === "e" || e.key === "E") {
            e.preventDefault();
        }
    };

    const handleValidate = async () => {
        const errors = {};
        let fileImages = [];

        for (let i = 0; i < images.length; i++) {
            if (images[i] instanceof File) {
                const result = processImage(images[i]);
                fileImages.push(result);
            }
        }

        const processedImages = await Promise.all(fileImages);
        const invalidAspectRatio = processedImages.filter(item => item.is4by3 === false);
        const invalidFileTypes = processedImages.filter(item => !IMAGE_FILE_TYPES.includes(item.file.type));
        const invalidOldFileSize = images.filter(item => item.size > MAXIMUM_FILE_SIZE_LIMIT);
        const invalidNewFileSize = processedImages.filter(item => item.file.size > MAXIMUM_FILE_SIZE_LIMIT);

        if (images.length < MINIMUM_FILE_UPLOAD_LIMIT) {
            errors.images = MESSAGES.MINIMUM_UPLOAD_FILE_LIMIT;
        }
        else if (invalidAspectRatio.length) {
            errors.images = MESSAGES.FILE_UPLOAD_ASPECT_RATIO;
        }
        else if (invalidFileTypes.length) {
            errors.images = MESSAGES.VALID_IMAGE_FILE_TYPE;
        }
        else if (invalidNewFileSize.length || invalidOldFileSize.length) {
            errors.images = MESSAGES.UPLOAD_FILE_SIZE_LIMIT;
        }

        return errors;
    };

    const editImageFormik = useFormik({
        initialValues: { images: [] },
        validate: handleValidate,
        onSubmit: async () => {
            let uploadedImages = await Promise.all(
                images?.filter(val => typeof val !== "string").map(val => {
                    const formData = new FormData();
                    formData.append("file", val);
                    formData.append("type", FILE_UPLOAD_TYPE.CAR);
                    return uploadImage(formData);
                })
            );

            let fileError = uploadedImages.find(item => item && item.status === false && item.error === "File too large");

            if (fileError) {
                Toaster(TOAST_TYPE.ERROR, MESSAGES.UPLOAD_FILE_SIZE_LIMIT);
                return;
            }

            uploadedImages = uploadedImages.filter(item => item !== undefined).map((val) => val.data.fileUrl);
            uploadedImages = [
                ...uploadedImages,
                ...images.filter(item => typeof (item) === "string" && item.startsWith("http")).map(val => {
                    let splitValue = val.split("/");
                    return splitValue[4];
                })
            ];
            props.update({ images: uploadedImages, car_id: props.car._id }, props.loader, props.car.slug);
            props.closeEditCarModal();
        },
    });

    const setInputValues = () => {
        editBasicCarFormik.setValues({
            name: props.car.name,
            price: props.car.price,
            model: props.car.model,
            brand: props.car.brand,
        });

        editDescriptionFormik.setValues({
            description: props.car.description,
        });

        editFeaturesFormik.setValues({
            mileage: props.car.features.mileage,
            bootSpace: props.car.features.boot_space,
            seats: props.car.features.seats,
            engine: props.car.features.engine,
            bhp: props.car.features.bhp,
            transmission: props.car.features.transmission,
        });

        dispatch({ type: COMMON_ACTIONS.SET_IMAGES, key: "selectedImage", value: props.car.images });
        dispatch({ type: COMMON_ACTIONS.SET_IMAGES, key: "images", value: props.car.images });
    };

    const closeModal = () => {
        props.closeEditCarModal();
        setInputValues();
    };

    useEffect(() => {
        if (Object.keys(props.car).length) {
            setInputValues();
        }
    }, [props.car]);

    return (
        <div className={`modal ${props.show ? "show d-block" : ""}`}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{props.car.name}</h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={closeModal}
                        ></button>
                    </div>
                    <div className="modal-body">
                        {props.type === EDIT_CAR_TYPE.NAME ?
                            <Fragment>
                                <div className="px-3 pt-2">
                                    <label className="mb-1 d-flex align-items-center">
                                        Name
                                        {editBasicCarFormik.touched.name && editBasicCarFormik.errors.name ? <p className="error my-0 ms-1">{editBasicCarFormik.errors.name}</p> : null}
                                    </label>
                                    <MdEditor
                                        plugins={EDITOR_PLUGINS}
                                        value={editBasicCarFormik.values.name || ""}
                                        className={styles.basicCarDetailsEditor}
                                        renderHTML={text => mdParser.render(text)}
                                        onChange={({ text }) => editBasicCarFormik.setFieldValue("name", text)}
                                        view={{ menu: true, md: true, html: false }}
                                    />
                                </div>
                                <div className="px-3 pt-2">
                                    <label className="mb-1 d-flex align-items-center">
                                        Brand
                                        {editBasicCarFormik.touched.brand && editBasicCarFormik.errors.brand ? <p className="error my-0 ms-1">{editBasicCarFormik.errors.brand}</p> : null}
                                    </label>
                                    <MdEditor
                                        plugins={EDITOR_PLUGINS}
                                        value={editBasicCarFormik.values.brand || ""}
                                        className={styles.basicCarDetailsEditor}
                                        renderHTML={text => mdParser.render(text)}
                                        onChange={({ text }) => editBasicCarFormik.setFieldValue("brand", text)}
                                        view={{ menu: true, md: true, html: false }}
                                    />
                                </div>
                                <div className="px-3 pt-2">
                                    <label className="mb-1 d-flex align-items-center">
                                        Model
                                        {editBasicCarFormik.touched.model && editBasicCarFormik.errors.model ? <p className="error my-0 ms-1">{editBasicCarFormik.errors.model}</p> : null}
                                    </label>
                                    <MdEditor
                                        value={editBasicCarFormik.values.model.toString()}
                                        plugins={EDITOR_PLUGINS}
                                        className={styles.basicCarDetailsEditor}
                                        renderHTML={text => mdParser.render(text)}
                                        onChange={({ text }) => editBasicCarFormik.setFieldValue("model", text)}
                                        view={{ menu: true, md: true, html: false }}
                                    />
                                </div>
                                <div className="px-3 pt-2">
                                    <label className="mb-1">
                                        Price
                                        {editBasicCarFormik.touched.price && editBasicCarFormik.errors.price ? <p className="error my-0 ms-1">{editBasicCarFormik.errors.price}</p> : null}
                                    </label>
                                    <input
                                        type="number"
                                        name="price"
                                        id="price"
                                        className="form-control px-3 py-2"
                                        value={editBasicCarFormik.values.price}
                                        onChange={editBasicCarFormik.handleChange}
                                        onBlur={editBasicCarFormik.handleBlur}
                                        onKeyDown={handleKeyDown}
                                    />
                                </div>
                            </Fragment> : props.type === EDIT_CAR_TYPE.DESCRIPTION ?
                                <div className="form-group description h-100" data-color-mode="light">
                                    <label className="mb-1">Enter Description</label>
                                    <MdEditor
                                        plugins={EDITOR_PLUGINS}
                                        className={styles.descriptionEditor}
                                        renderHTML={text => mdParser.render(text)}
                                        value={editDescriptionFormik.values.description}
                                        onChange={({ text }) => editDescriptionFormik.setFieldValue("description", text)}
                                        view={{ menu: true, md: true, html: false }}
                                    />
                                    {editDescriptionFormik.errors.description && editDescriptionFormik.touched.description ? (
                                        <div className="error">{editDescriptionFormik.errors.description}</div>
                                    ) : null}
                                </div> : props.type === EDIT_CAR_TYPE.IMAGES ?
                                    <FormikProvider value={editImageFormik}>
                                        <DropImage formik={editImageFormik} />
                                    </FormikProvider> : props.type === EDIT_CAR_TYPE.FEATURES ?
                                        <Fragment>
                                            <div className='px-2 pb-2'>
                                                <label className="mb-1">Mileage</label>
                                                <input
                                                    type="number"
                                                    name="mileage"
                                                    id="price"
                                                    className="form-control px-3 py-2"
                                                    value={editFeaturesFormik.values.mileage}
                                                    onChange={editFeaturesFormik.handleChange}
                                                    onBlur={editFeaturesFormik.handleBlur}
                                                    onKeyDown={handleKeyDown}
                                                />
                                                {editFeaturesFormik.touched.mileage && editFeaturesFormik.errors.mileage ? <p className="error mb-0">{editFeaturesFormik.errors.mileage}</p> : null}
                                            </div>
                                            <div className='px-2 pb-2'>
                                                <label className="mb-1">Transmission</label>
                                                <select
                                                    name="transmission"
                                                    id="transmission"
                                                    value={editFeaturesFormik.values.transmission}
                                                    onBlur={editFeaturesFormik.handleBlur}
                                                    onChange={editFeaturesFormik.handleChange}
                                                >
                                                    <option value="">Select Transmission</option>
                                                    <option value="AUTOMATIC">Automatic</option>
                                                    <option value="MANUAL">Manual</option>
                                                    <option value="CVT">CVT</option>
                                                </select>
                                                {
                                                    editFeaturesFormik.touched.transmission && editFeaturesFormik.errors.transmission ?
                                                        <p className="error mb-0">{editFeaturesFormik.errors.transmission}</p>
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className='px-2 pb-2'>
                                                <label className="mb-1">Seats</label>
                                                <input
                                                    type="number"
                                                    name="seats"
                                                    id="seats"
                                                    className="form-control px-3 py-2"
                                                    value={editFeaturesFormik.values.seats}
                                                    onChange={editFeaturesFormik.handleChange}
                                                    onBlur={editFeaturesFormik.handleBlur}
                                                    onKeyDown={handleKeyDown}
                                                />
                                                {editFeaturesFormik.touched.seats && editFeaturesFormik.errors.seats ? <p className="error mb-0">{editFeaturesFormik.errors.seats}</p> : null}
                                            </div>
                                            <div className='px-2 pb-2'>
                                                <label className="mb-1">Engine</label>
                                                <input
                                                    type="number"
                                                    name="engine"
                                                    id="engine"
                                                    className="form-control px-3 py-2"
                                                    value={editFeaturesFormik.values.engine}
                                                    onChange={editFeaturesFormik.handleChange}
                                                    onBlur={editFeaturesFormik.handleBlur}
                                                    onKeyDown={handleKeyDown}
                                                />
                                                {editFeaturesFormik.touched.engine && editFeaturesFormik.errors.engine ? <p className="error mb-0">{editFeaturesFormik.errors.engine}</p> : null}
                                            </div>
                                            <div className='px-2 pb-2'>
                                                <label className="mb-1">BHP</label>
                                                <input
                                                    type="number"
                                                    name="bhp"
                                                    id="bhp"
                                                    className="form-control px-3 py-2"
                                                    value={editFeaturesFormik.values.bhp}
                                                    onChange={editFeaturesFormik.handleChange}
                                                    onBlur={editFeaturesFormik.handleBlur}
                                                    onKeyDown={handleKeyDown}
                                                />
                                                {editFeaturesFormik.touched.bhp && editFeaturesFormik.errors.bhp ? <p className="error mb-0">{editFeaturesFormik.errors.bhp}</p> : null}
                                            </div>
                                            <div className='px-2 pb-2'>
                                                <label className="mb-1">Boot Space</label>
                                                <input
                                                    type="number"
                                                    name="bootSpace"
                                                    id="bootSpace"
                                                    className="form-control px-3 py-2"
                                                    value={editFeaturesFormik.values.bootSpace}
                                                    onChange={editFeaturesFormik.handleChange}
                                                    onBlur={editFeaturesFormik.handleBlur}
                                                    onKeyDown={handleKeyDown}
                                                />
                                                {
                                                    editFeaturesFormik.touched.bootSpace && editFeaturesFormik.errors.bootSpace ?
                                                        <p className="error mb-0">{editFeaturesFormik.errors.bootSpace}</p>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </Fragment>
                                        :
                                        props.type === EDIT_CAR_TYPE.LOCATION ?
                                            <FormikProvider value={editCarLocationFormik}>
                                                <div className='form-group'>
                                                    <label className='w-100'>Enter location</label>
                                                    <Autocomplete
                                                        onPlaceSelected={(locData) => {
                                                            const location = locData.formatted_address,
                                                                latitude = locData.geometry.location.lat(),
                                                                longitude = locData.geometry.location.lng();

                                                            editCarLocationSchema.setValues({
                                                                location, latitude, longitude,
                                                            });

                                                        }}
                                                        defaultValue={props.car?.location}
                                                    />
                                                </div>
                                            </FormikProvider>
                                            : null
                        }
                    </div>

                    <div className="modal-footer">
                        <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={() => {
                                if (props.type === EDIT_CAR_TYPE.NAME) editBasicCarFormik.submitForm();
                                else if (props.type === EDIT_CAR_TYPE.DESCRIPTION) editDescriptionFormik.submitForm();
                                else if (props.type === EDIT_CAR_TYPE.FEATURES) editFeaturesFormik.submitForm();
                                else if (props.type === EDIT_CAR_TYPE.IMAGES) editImageFormik.submitForm();
                            }}
                        >
                            Update
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
};

const mapDispatchToProps = (dispatch) => ({
    update: (payload, loader, navigate) => dispatch(VendorCarAction.update(payload, loader, navigate)),
});

export default connect(null, mapDispatchToProps)(EditCar)