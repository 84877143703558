import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect, useSelector } from "react-redux";

import { Pagination } from "../../../components";
import { CommonAction } from "../../../store/common/thunk";
import tableStyles from  "../../../components/Table/style.module.css";
import commonStyles from  "../../Common/styles/common.module.css";

const Notifications = ({ loader, notificationList }) => {
    const { user, notificationTab } = useSelector((state) => state.common);

    const [pagination, setPagination] = useState({ offset: 0, limit: 10 });

    const getNotifications = async () => {
        notificationList({ userType: user.userType, ...pagination }, loader, true);
    };

    useEffect(() => {
        getNotifications();
    }, [pagination]);

    return (
        <div className={commonStyles["vendor-listing"]}>
            <div className="d-flex justify-content-between align-items-center mb-4">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb bg-transparent m-0">
                        <li className="breadcrumb-item">
                            <Link to="/" className="text-decoration-none text-muted">
                                <i className="fas fa-home me-1"></i>Dashboard
                            </Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <span className="text-primary">Notifications</span>
                        </li>
                    </ol>
                </nav>
            </div>
            <div className={`${tableStyles["listing-table"]} shadow-sm rounded`}>
                <div className={`${tableStyles["listing-table-scrl"]} p-3`}>
                    <table className="table table-hover" cellPadding={0} cellSpacing={0}>
                        <thead className="bg-light">
                            <tr>
                                <th className="py-3" width="5%">#</th>
                                <th className="py-3" width="20%">Name</th>
                                <th className="py-3" width="25%">Title</th>
                                <th className="py-3" width="50%">Body</th>
                            </tr>
                        </thead>

                        <tbody>
                            {notificationTab.notifications.map((notify, index) => (
                                <tr key={notify._id} className="align-middle">
                                    <td className="py-3">{index + 1}</td>
                                    <td className="py-3">
                                        <div className="d-flex align-items-center">
                                            {/* <div className="avatar-circle me-2">
                                                {notify.sender_id?.firstName?.charAt(0)}
                                            </div> */}
                                            {notify.sender_id?.firstName}
                                        </div>
                                    </td>
                                    <td className="py-3 fw-semibold">{notify.title}</td>
                                    <td className="py-3 text-muted">{notify.body}</td>
                                </tr>
                            ))}
                        </tbody> 
                    </table>
                </div>
            </div>
            <div className="mt-4">
                <Pagination
                    count={notificationTab.count + 1}
                    setPagination={setPagination}
                />
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    notificationList: (params, loader, replace) => dispatch(CommonAction.notificationsList(params, loader, replace))
});

export default connect(null, mapDispatchToProps)(Notifications);
