import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import Chart from "react-apexcharts"; // Import ApexCharts
import { AdminDashboardAction } from "../../../store/admin/thunk";
import { APP_ROUTES } from "../../../routes";
import dashboardStyles from "../../Vendor/Dashboard/style.module.css";
import waveimg from '../../../assets/images/wave-img.png';

function AdminDashboard({ loader, getDashboardDetails }) {
    const { dashboard: { dashboardDetails } } = useSelector((state) => state.admin);

    const [chartOptions, setChartOptions] = useState({
        chart: {
            id: "line-chart",
            toolbar: {
                show: true,
            },
        },
        xaxis: {
            categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"], // Month names
        },
        stroke: {
            curve: "smooth", // Smooth lines
        },
        colors: ["#FF4560", "#008FFB", "#00E396"], // Line colors
        title: {
            text: "Booking Trends",
            align: "left",
        },
        legend: {
            position: "top",
        },
    });

    const [chartSeries, setChartSeries] = useState([
        {
            name: "Properties",
            data: [10, 45, 30, 80, 65, 140, 160, 190, 140, 155, 125, 180], // Property data points
        },
        {
            name: "Cars",
            data: [20, 35, 50, 40, 90, 80, 120, 100, 150, 130, 170, 160], // Car data points
        },
        {
            name: "Tours",
            data: [5, 25, 40, 60, 45, 95, 85, 140, 110, 90, 130, 150], // Tour data points
        },
    ]);

    const adminDashboard = async () => {
        getDashboardDetails(loader);
    };

    useEffect(() => {
        adminDashboard();
    }, []);

    return (
        <Fragment>
            <div className={dashboardStyles["proerty-tabs"]}>
                <div className="row">
                    <div className="col-md-3">
                        <Link to={APP_ROUTES.REVENUES_LIST}>
                            <div className={`${dashboardStyles["inner-tabs"]} ${dashboardStyles["card-shadow"]} ${dashboardStyles["card-shadow01"]} `}>
                                <span>
                                    <i className="fas fa-coins"></i>
                                </span>
                                <h5>Admin Total Revenue</h5>
                                <h4>{dashboardDetails.total_revenue}</h4>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-3">
                        <Link to={APP_ROUTES.DAILY_REVENUE}>
                            <div className={`${dashboardStyles["inner-tabs"]} ${dashboardStyles["card-shadow"]} ${dashboardStyles["card-shadow02"]} `}>
                                <span>
                                    <i className="fas fa-chart-pie"></i>
                                </span>
                                <h5>Daily Revenue</h5>
                                <h4>{dashboardDetails.daily_revenue}</h4>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-3">
                        <Link to={APP_ROUTES.USERS_LIST}>
                            <div className={`${dashboardStyles["inner-tabs"]} ${dashboardStyles["card-shadow"]} ${dashboardStyles["card-shadow03"]} `}>
                                <span>
                                    <i className="fas fa-users"></i>
                                </span>
                                <h5>Total Users</h5>
                                <h4>{dashboardDetails.total_users}</h4>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-3">
                        <Link to={APP_ROUTES.VENDOR_LIST}>
                            <div className={`${dashboardStyles["inner-tabs"]} ${dashboardStyles["card-shadow"]} ${dashboardStyles["card-shadow04"]} `}>
                                <span>
                                    <i className="fas fa-user-friends"></i>
                                </span>
                                <h5>Total Vendors</h5>
                                <h4>{dashboardDetails.total_vendors}</h4>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="proerty-tabs mt-4">
                <div className="row">
                    <div className="col-md-4">
                        <Link to={APP_ROUTES.VENDOR_LIST}>
                            <div className={`${dashboardStyles["inner-tabs"]} ${dashboardStyles["card-shadow"]} ${dashboardStyles["card-shadow05"]} `}>
                                <span>
                                    <i className="fas fa-city"></i>
                                </span>
                                <h5>Property Booking Requests</h5>
                                <h4>{dashboardDetails.total_booking_requests?.property}</h4>
                            </div>
                        </Link>
                    </div>

                    <div className="col-md-4">
                        <div className={`${dashboardStyles["inner-tabs"]} ${dashboardStyles["card-shadow"]} ${dashboardStyles["card-shadow01"]} `}>
                            <span>
                                <i className="fas fa-car"></i>
                            </span>
                            <h5>Vehicle Booking Requests</h5>
                            <h4>{dashboardDetails.total_booking_requests?.vehicle}</h4>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className={`${dashboardStyles["inner-tabs"]} ${dashboardStyles["card-shadow"]} ${dashboardStyles["card-shadow02"]} `}>
                            <span>
                                <i className="fa fa-fighter-jet"></i>
                            </span>
                            <h5>Tour Booking Requests</h5>
                            <h4>{dashboardDetails.total_booking_requests?.tours}</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pie-chart">
                <div className="row">
                    <div className="col-md-4">
                        <div className="pie-chart-left card-shadow">
                            <h3>Properties</h3>
                            <Chart
                                options={{
                                    chart: {
                                        id: "property-chart",
                                    },
                                    xaxis: {
                                        categories: Array.from({ length: 12 }, (_, i) => `Month ${i + 1}`),
                                    },
                                }}
                                series={[
                                    {
                                        name: "Properties",
                                        data: dashboardDetails.bookedProperties || [],
                                    },
                                ]}
                                type="line"
                                height={200}
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="pie-chart-right card-shadow">
                            <h3>Cars</h3>
                            <Chart
                                options={{
                                    chart: {
                                        id: "car-chart",
                                    },
                                    xaxis: {
                                        categories: Array.from({ length: 12 }, (_, i) => `Month ${i + 1}`),
                                    },
                                }}
                                series={[
                                    {
                                        name: "Cars",
                                        data: dashboardDetails.bookedCars || [],
                                    },
                                ]}
                                type="line"
                                height={200}
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="pie-chart-left card-shadow">
                            <h3>Tours</h3>
                            <Chart
                                options={{
                                    chart: {
                                        id: "tour-chart",
                                    },
                                    xaxis: {
                                        categories: Array.from({ length: 12 }, (_, i) => `Month ${i + 1}`),
                                    },
                                }}
                                series={[
                                    {
                                        name: "Tours",
                                        data: dashboardDetails.bookedTours || [],
                                    },
                                ]}
                                type="line"
                                height={200}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

const mapDispatchToProps = (dispatch) => ({
    getDashboardDetails: (loader) => dispatch(AdminDashboardAction.getDashboardDetails(loader)),
});

export default connect(null, mapDispatchToProps)(AdminDashboard);