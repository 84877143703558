export const API_URL = {
  PROPERTY_DETAIL: "property/details",
  VENDOR_PROPERTY_DETAIL: "property/details/vendor",
  EDIT_PROPERTY: "property",
  EDIT_CAR: "vendor/update/car",
  TOUR_API: "tour",
  CURRENCY_URL: "https://ipapi.co/currency",
  CALENDAR_URL: "property/calendar/pricing",
  PROPERTY_LIST: "property",
};

export const API_ENDPOINTS = {
    GET_USER_DETAILS: "v1/common/checkUserType",
    PROPERTY_CRUD: "v2/property",
    LIST_CARS: "v1/car",
    LIST_CATEGORIES: "v1/category",
    SPECIFIC_PROPERTY: "v1/property/details/vendor",
    TOUR_CRUD: "v1/tour",
    LIST_NOTIFICATIONS: "v1/notification/listing",
    LIST_AREAS_BUILDINGS: "v1/location/area/building",
    CREATE_UPDATE_AREA: "v1/location/area",
    CREATE_UPDATE_BUILDING: "v1/location/building",
    APPROVE_CAR: "v1/car/approve",
    CREATE_UPDATE_CATEGORY: "v1/category",
    CREATE_UPDATE_SUB_CATEGORY: "v1/category/subCategory",
    CREATE_UPDATE_LIST_CITIES: "v1/location/city",
    SPECIFIC_CITY_AREA_BUILDINGS: "v1/location/details",
    ADMIN_DASHBOARD: "v1/admin/dashboard",
    FAQ_CRUD: "v1/faq",
    ADMIN_INVOICE_LIST: "v1/payout/admin/invoiceList",
    VENDOR_INVOICE_LIST: "v1/payout/invoiceList",
    ADMIN_SPECIFIC_INVOICE: "v1/payout/admin/bookingsInInvoice",
    VENDOR_SPECIFIC_INVOICE: "v1/payout/bookingsInInvoice",
    MARK_PAYOUT: "v1/payout/admin/markPayoutSuccess",
    PROMOTION_CRUD: "v1/promotion",
    SPECIFIC_PROMOTION: "v1/promotion/promotionId",
    APPROVE_PROPERTY: "v1/property/approve",
    LIST_PROPERTY_BEDS: "v1/property/beds/category",
    LIST_PROPERTY_AMENITIES: "v1/amenity",
    LIST_SUB_ADMIN: "v1/admin/get/subadmins",
    UPDATE_SUB_ADMIN: "v1/admin/update/subadmin",
    CREATE_SUB_ADMIN: "v1/admin/create/subadmin",
    APPROVE_TOUR: "v1/tour/approve",
    LIST_USERS: "v1/admin/get/users",
    UPDATE_USERS: "v1/admin/update/user",
    SPECIFIC_USER: "v1/admin/get/user",
    LIST_VENDORS: "v1/vendor/list",
    SPECIFIC_VENDOR: "v1/vendor",
    UPDATE_VENDOR: "v1/vendor/admin",
    VOUCHER_CRUD: "v1/voucher",
    SPECIFIC_VOUCHER: "v1/voucher/voucherId",
    CLAIMED_VOUCHER_USERS: "v1/voucher/claimedUsers",
    CAR_CRUD: "v1/car",
    SPECIFIC_CAR: "v1/car/details",
    BOOKING_PAYOUTS: "v1/payout/readyToPayoutBookings",
    SAVE_PAYOUT_CARD: 'v1/payout/bankInfo',
    LIST_PAYOUT_CARD: 'v1/payout/bankInfo/list',
    UPDATE_PAYOUT_CARD: 'v1/payout/bankInfo',
    UPDATE_PAYOUT_CARD_STATUS: 'v1/payout/bankInfo/manage',
    SPECIFIC_PAYOUT_CARD: 'v1/payout/bankInfo',
    DELETE_PAYOUT_CARD: 'v1/payout/bankInfo',
    GENERATE_INVOICE: "v1/payout/generateInvoice",
    PROPERTY_PROMOTION_CRUD: "v1/promotion/property",
    LIST_UPDATE_PROPERTY_PRICES: "v1/property/calendar/pricing",
    LIST_BOOKING_REQUESTS: "v1/vendor/booking/requests",
    SPECIFIC_BOOKING_REQUEST: "v1/booking/reservationDetails",
    LIST_PROPERTY_RESERVATIONS: "v1/property/reservations",
    LIST_CAR_RESERVATIONS: "v1/car/reservations",
    ACCEPT_BOOKINGS: "v1/booking/acceptPayment",
    TOUR_SUB_CATEGORY_CRUD: "v1/tour/subCategory",
    SPECIFIC_TOUR: "v1/tour/details/vendor",
    LIST_TOUR_PRICES: "v1/tour/calendarData",
    UPDATE_TOUR_CALENDAR: "v1/tour/calendar",
    LIST_CMS: "v1/cms",
    UPDATE_CMS: "v1/admin/update/cms",
    AIRBNB_PROPERTY: "v1/property/airbnb/listing",
    ADD_TEAM:"v1/employee/add",
    GET_EMPLOYEE_LIST:"v1/employee/getlist",
    UPDATE_EMPLOYEE_LIST:"v1/employee/update",
    DELETE_EMPLOYEE:"v1/employee/delete",
    UPDATE_STATUS:"v1/employee/status"
};
